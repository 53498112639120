import { eachDayOfInterval, format, isValid } from 'date-fns';
import { Entry } from '../api/hooks/useBadgesAwardsChartData';

// TODO: Should probably have more universal type for `data` so it could be reused for other charts
export const fillWithEmptyDataForChart = (
  data: Entry[],
  startDate: string | undefined = undefined,
  endDate: string | undefined = undefined,
): Entry[] => {
  if (data.length === 0) {
    return data;
  }

  const parseDate = (dateStr: string | undefined, fallback: string): Date => {
    const parsedDate = new Date(dateStr || fallback);
    return isValid(parsedDate) ? parsedDate : new Date(fallback);
  };

  const start = parseDate(startDate, data[0].date);
  const end = parseDate(endDate, data[data.length - 1].date);

  const allDays = eachDayOfInterval({
    start,
    end,
  });

  // Map each day into an object with `count: 0` if there's no data for that day
  const filledData = allDays.map((day) => {
    const formattedDate = format(day, 'yyyy-MM-dd');

    const existingEntry = data.find((entry) => entry.date === formattedDate);

    return (
      existingEntry || {
        date: formattedDate,
        count: 0,
      }
    );
  });

  return filledData;
};
