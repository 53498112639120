import { GroupChannelProps } from '@sendbird/uikit-react/GroupChannel';
import { GroupChannelProvider } from '@sendbird/uikit-react/GroupChannel/context';
import { GroupCustomChannelUI } from './GroupCustomChannelUI';

export const GroupCustomChannel = (props: GroupChannelProps) => {
  return (
    <GroupChannelProvider {...props}>
      <GroupCustomChannelUI />
    </GroupChannelProvider>
  );
};
