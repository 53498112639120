import { TextInput, required } from 'react-admin';
import { SimpleStack } from '../Moonstar/SimpleStack';
import { MoonstarEditForm } from '../Moonstar/MoonstarEditForm';
import { MoonstarEdit } from '../Moonstar/MoonstarEdit';
import { MoonstarEditFormToolbar } from '../Moonstar/MoonstarEditFormToolbar';

export const NudgeCategoryEdit = () => (
  <MoonstarEdit redirect="list">
    <MoonstarEditForm toolbar={<MoonstarEditFormToolbar hideDelete />}>
      <SimpleStack>
        <TextInput source="name" validate={required()} />
      </SimpleStack>
    </MoonstarEditForm>
  </MoonstarEdit>
);
