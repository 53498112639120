import { TextField, TextFieldProps } from '@mui/material';

type SearchTextInputProps = Omit<TextFieldProps, 'value' | 'onChange'> & {
  searchTerm: string;
  setSearchTerm: (text: string) => void;
};

export const SearchTextInput = ({
  searchTerm,
  setSearchTerm,
  ...fieldProps
}: SearchTextInputProps) => {
  return (
    <TextField
      value={searchTerm}
      onChange={(elem) => setSearchTerm(elem.target.value)}
      fullWidth
      {...fieldProps}
    />
  );
};
