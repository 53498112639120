import { ImageField, ImageInput, NumberInput, TextInput } from 'react-admin';
import { MoonstarCreate } from '../../Moonstar/MoonstarCreate';
import { SimpleStack } from '../../Moonstar/SimpleStack';
import { MoonstarCreateForm } from '../../Moonstar/MoonstarCreateForm';
import { useParams } from 'react-router-dom';
import { requiredIfFieldsAreBlank } from '../../../../utils/validation';

const validation = requiredIfFieldsAreBlank(
  { everyField: ['image', 'content'] },
  'Image or content must be set',
);

export const NudgeSlideCreate = () => {
  const { nudgeId } = useParams();

  return (
    <MoonstarCreate redirect={() => `nudges/${nudgeId}/slides`}>
      <MoonstarCreateForm>
        <SimpleStack width="100%">
          <NumberInput
            source="nudgeId"
            defaultValue={parseInt(nudgeId ?? '')}
            sx={{ display: 'none' }}
          />
          <ImageInput
            source="image"
            accept={{ 'image/*': ['.jpg', '.jpeg', '.png', '.gif'] }}
            validate={validation}
            sx={{ alignItems: 'start' }}
          >
            <ImageField source="src" title="title" />
          </ImageInput>
          <TextInput
            multiline
            defaultValue=""
            source="content"
            fullWidth
            validate={validation}
          />
        </SimpleStack>
      </MoonstarCreateForm>
    </MoonstarCreate>
  );
};
