import { useUser, useOrganizationList, useAuth, useSession } from '@clerk/clerk-react';
import { useMemo } from 'react';
import { sortBy } from '../utils/sortBy';
import { OrganizationMembershipResource, SetActive, UserResource } from '@clerk/types';

export const useUserOrganization = () => {
  const { isLoaded, user } = useUser();
  const { orgId } = useAuth();

  const activeOrganization = useMemo(() => {
    if (!isLoaded || !user?.organizationMemberships || !orgId) return;

    return user.organizationMemberships.find((m) => m.organization.id === orgId);
  }, [orgId, user]);

  return {
    isLoading: !isLoaded,
    activeOrganization,
    user: user ?? undefined,
  };
};

export const useUserOrganizations = (): {
  lastUsedOrganization?: OrganizationMembershipResource;
  activeOrganization?: OrganizationMembershipResource;
  organizations: OrganizationMembershipResource[];
  isLoading: boolean;
  setActiveOrganization?: SetActive;
  user?: UserResource;
} => {
  const { isLoading, activeOrganization, user } = useUserOrganization();
  const { setActive: setActiveOrganization } = useOrganizationList();
  const { orgId } = useAuth();
  const { session } = useSession();

  const lastUsedOrganization = useMemo(() => {
    if (!user?.organizationMemberships || !session) return;

    return user.organizationMemberships.find(
      (m) => m.organization.id === session.lastActiveOrganizationId,
    );
  }, [orgId, session]);

  // Display as oldest first (so new tenants appear at the bottom)
  const organizations = useMemo(() => {
    if (!user?.organizationMemberships) return [];

    return sortBy(
      [...user.organizationMemberships],
      (org) => +org.organization.createdAt,
      'ascending',
    );
  }, [user]);

  return {
    isLoading,
    user,
    activeOrganization,
    lastUsedOrganization,
    organizations,
    setActiveOrganization,
  };
};
