import React from 'react';
import { PostReactionAndCommentsDiv } from './styled';
import { PostCommentIcon } from '../../../customIcons/PostCommentIcon';
import HeartIconImage from '../../../../../assets/reaction-icon.png';

export const PostPreviewFooter = () => {
  return (
    <PostReactionAndCommentsDiv>
      <img alt="reaction-icon" src={HeartIconImage} width={24} />
      <PostCommentIcon />
    </PostReactionAndCommentsDiv>
  );
};
