import { useAuthState } from 'react-admin';
import { useFeatureFlags } from '../components/FeatureFlagGuard';
import { useGetCurrentUser } from './useGetCurrentUser';
import { useContext } from 'react';
import { UserRoleContext } from '../auth/userRoleContext';

export const useDashboardVisibility = () => {
  const { authenticated, isPending } = useAuthState();
  const options = {
    enabled: isPending ? false : authenticated,
  };
  const { data: featureFlags } = useFeatureFlags(options);
  const { data: currentUser } = useGetCurrentUser({
    ...options,
  });
  const context = useContext(UserRoleContext);
  const isAdmin = context.userRole === 'Admin';

  return {
    showDashboard:
      (featureFlags?.withAdminDashboard && isAdmin) ||
      currentUser?.email === 'support@moonstar.ai',
      isAdmin
  };
};
