export const getCommonDefaultValues = () => ({
  publishingDate: new Date().toISOString(),
  allowComments: true,
  allowReactions: true,
  isImportant: false,
  visibleForEveryone: true,
  departments: new Array<number>(),
  jobTitles: new Array<number>(),
  regions: new Array<number>(),
  units: new Array<number>(),
  taggedUsers: new Array<number>(),
});
