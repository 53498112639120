import { useDataProvider } from 'react-admin';
import { AdminDataProvider } from '../api/adminDataProvider';
import { useQuery, queryOptions } from '@tanstack/react-query';

const getCurrentUserOptions = (provider: AdminDataProvider) =>
  queryOptions({
    queryKey: ['currentUser'],
    queryFn: provider.fetchCurrentUser,
  });

export const useGetCurrentUser = (
  options: Partial<ReturnType<typeof getCurrentUserOptions>> = {},
) => {
  const provider = useDataProvider<AdminDataProvider>();

  return useQuery({
    ...getCurrentUserOptions(provider),
    ...options,
  });
};
