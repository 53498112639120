import React from 'react';
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  LineChart,
  Line,
} from 'recharts';
import { Card, CardContent, CardHeader, useTheme } from '@mui/material';
import { format, startOfMonth, endOfMonth } from 'date-fns';
import { sortBy } from '../../../utils/sortBy';
import { useUsersChartData } from '../../../api/hooks/useUsersChartData';

export const UsersChart: React.FC = () => {
  const {
    moonstar: { charts },
  } = useTheme();

  const now = new Date();
  const startDate = startOfMonth(now);
  const endDate = endOfMonth(now);

  const { data: chartData, isPending } = useUsersChartData({
    createdAfter: startDate.toISOString(),
    createdBefore: endDate.toISOString(),
  });

  const sortedData = sortBy(chartData || [], (x) => x.date);

  if (isPending) {
    return <p>Loading...</p>;
  }

  return (
    <Card sx={{ boxShadow: 3 }}>
      <CardHeader title="Active users" />
      <CardContent>
        <ResponsiveContainer width="100%" height={400}>
          <LineChart data={sortedData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey="date"
              tickFormatter={(date) => format(new Date(date), 'MMM dd')}
            />
            <YAxis
              yAxisId="left"
              orientation="left"
              stroke={charts.palette.color1}
              tickFormatter={(value) => Math.round(value).toString()}
            />
            <YAxis
              yAxisId="right"
              orientation="right"
              domain={[0, 100]}
              stroke={charts.palette.color3}
            />
            <Tooltip />
            <Legend />
            <Line
              yAxisId="left"
              type="monotone"
              dataKey="count"
              name="active users"
              stroke={charts.palette.color1}
            />
            <Line
              yAxisId="right"
              type="monotone"
              dataKey="percentage"
              name="% of all users"
              stroke={charts.palette.color3}
            />
          </LineChart>
        </ResponsiveContainer>
      </CardContent>
    </Card>
  );
};
