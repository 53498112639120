import React, { createContext, useContext, useState, ReactNode } from 'react';

type PostCreateContextType = {
  formTypeId: number | undefined;
  selectedTopic: number | undefined;
  formData: Array<any | undefined>;
  setFormData: React.Dispatch<React.SetStateAction<Array<any | undefined>>>;
  // eslint-disable-next-line no-unused-vars
  handleTopicChange: (typeId: number, topicId: number) => void;
};

const PostCreateContext = createContext<PostCreateContextType | undefined>(undefined);

export const PostCreateProvider = ({ children }: { children: ReactNode }) => {
  const [formData, setFormData] = useState([undefined]);
  const [formTypeId, setFormTypeId] = useState<number>();
  const [selectedTopic, setSelectedTopic] = useState<number>();

  const handleTopicChange = (typeId: number, topicId: number) => {
    setSelectedTopic(topicId);
    setFormTypeId(typeId);
  };

  return (
    <PostCreateContext.Provider
      value={{ formTypeId, formData, setFormData, selectedTopic, handleTopicChange }}
    >
      {children}
    </PostCreateContext.Provider>
  );
};

export const usePostCreateContext = (): PostCreateContextType => {
  const context = useContext(PostCreateContext);
  if (!context) {
    throw new Error('usePostCreateContext must be used within a PostCreateProvider.');
  }
  return context;
};
