import { Box } from '@mui/material';
import type { GroupChannel } from '@sendbird/chat/groupChannel';
import { useState } from 'react';
import { useFetchSendBirdAppId } from '../../../api/hooks/useFetchSendBirdAppId';
import { AppLoading } from '../../AppLoading';
import { GroupCustomChannel } from './GroupCustomChannel/GroupCustomChannel';
import { GroupCustomChannelList } from './GroupCustomChannelList/GroupCustomChannelsList';
import { GroupCustomChannelSettings } from './GroupCustomChannelSettings/GroupCustomChannelSettings';
import { ForwardMessageProvider } from './ForwardMessageProvider';
import { sendBirdColorSet } from './colorSet';


// TODO: Rename to 'Groups' and remove current 'Groups' after all the work for custom web chat is done
export const GroupsCustom = () => {
  const { isPending: isSendBirdAppPending } = useFetchSendBirdAppId();
  const [currentChannel, setCurrentChannel] = useState<GroupChannel | null>();
  const [showSettings, setShowSettings] = useState(false);

  const handleChannelCreated = (channel: GroupChannel) => {
    setCurrentChannel(channel);
  };

  if (isSendBirdAppPending) {
    return <AppLoading />;
  }

  return (
    <ForwardMessageProvider>
      <Box
        sx={{
          display: 'flex',
          height: '100%',
          ...sendBirdColorSet
        }}
      >
        <GroupCustomChannelList
          onChannelSelect={(channel) => setCurrentChannel(channel)}
          onChannelCreated={handleChannelCreated}
          selectedChannelUrl={currentChannel?.url}
        />
        {currentChannel && (
          <GroupCustomChannel
            channelUrl={currentChannel.url}
            onChatHeaderActionClick={() => {
              setShowSettings(true);
            }}
          />
        )}

        {showSettings && currentChannel && (
          <GroupCustomChannelSettings
            channelUrl={currentChannel.url}
            onCloseClick={() => {
              setShowSettings(false);
            }}
          />
        )}
      </Box>
    </ForwardMessageProvider>
  );
};
