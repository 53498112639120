import { ResourcesValue } from '../../components/admin/AdminResources';
import { Badge } from '../../components/admin/Badges/types';
import { getTitleFromUrl } from '../../utils/getTitleFromUrl';
import { LifecycleHooks } from '../addLifecycleHooks';
import { AdminDataProvider } from '../adminDataProvider';
import { handleUpload } from '../files/fileUpload';

type TransformedBadge = {
  id: number;
  name: string;
  image: {
    src: string;
    title: string;
  };
};

type CreateBadge = {
  id: number;
  name: string;
  imageUrl?: string;
};

export const badgesDataTransform: LifecycleHooks[] = [
  {
    resource: ResourcesValue.BADGES,
    beforeSave: async (params, dataProvider) => {
      params.image = await handleUpload(
        params.image,
        'images',
        dataProvider as AdminDataProvider,
      );
      return params;
    },
  },
  {
    resource: ResourcesValue.BADGES,
    afterGetOne: async (badge) => {
      const badgeData: Badge = badge.data;

      // Mapping to adjust to react-admin ImageInput and ImageField in BadgesEdit
      if (badgeData.imageUrl) {
        badge.data = {
          id: badgeData.id,
          name: badgeData.name,
          image: {
            src: badgeData.imageUrl,
            title: getTitleFromUrl(badgeData.imageUrl),
          },
        };
      }

      return badge;
    },
    beforeSave: async (transformedBadge: TransformedBadge) => {
      // Mapping here so it doesn't have to be done on BE
      const badge: CreateBadge = {
        id: transformedBadge.id,
        name: transformedBadge.name,
        imageUrl: transformedBadge.image ? transformedBadge.image.src : undefined,
      };

      return badge;
    },
  },
];
