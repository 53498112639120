import { useCallback, useMemo } from 'react';
import { fetchUtils, Options } from 'react-admin';
import { HttpClient, HttpClientFetch } from '../api/httpClient';
import { useAuth } from '@clerk/clerk-react';
import { getApiUrl, getFileHostUrl } from '../api/getApiUrl';
import { useTenantId } from '../components/OrganizationGuard';

export const useHttpClient = (): HttpClient => {
  const tenantId = useTenantId();
  const { getToken } = useAuth();

  const sendRequest = useCallback<HttpClientFetch>(
    async (url, options) => {
      const token = await getToken();

      const user = {
        authenticated: !!token,
        token: `Bearer ${token}`,
      };

      return await fetchUtils.fetchJson(url, {
        ...options,
        user,
      });
    },
    [getToken],
  );

  const api = useCallback<HttpClientFetch>(
    async (path: string, options: Options = {}) => {
      if (!path.startsWith('/')) {
        throw new Error(`Path must start with /`);
      }

      const apiUrl = getApiUrl(tenantId);

      return sendRequest(`${apiUrl}${path}`, options);
    },
    [sendRequest, tenantId],
  );

  const fileHost = useCallback<HttpClientFetch>(
    async (path: string, options: Options = {}) => {
      if (!path.startsWith('/')) {
        throw new Error(`Path must start with /`);
      }

      const apiUrl = getFileHostUrl(tenantId);

      return sendRequest(`${apiUrl}${path}`, options);
    },
    [sendRequest, tenantId],
  );

  return useMemo(
    () => ({
      api,
      fileHost,
    }),
    [api, fileHost],
  );
};
