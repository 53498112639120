import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { Typography, useTheme } from '@mui/material';

export const DateInputError = ({ error }: { error: string }) => {
  const theme = useTheme();

  if (error) {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          margin: 8,
        }}
      >
        <ErrorOutlineIcon color="error" style={{ marginRight: 4 }} />
        <Typography color={theme.palette.error.main} variant="body2">
          {error}
        </Typography>
      </div>
    );
  } else return null;
};
