import {
  ChipField,
  Datagrid,
  DateField,
  ImageField,
  ReferenceArrayField,
  SingleFieldList,
  TextField,
} from 'react-admin';
import { MoonstarList } from '../Moonstar/MoonstarList';
import { BulkUserDisableAction } from './BulkUserDisableAction';
import { UserFilters } from './UserFilters';
import { UserToolbarActions } from './UserToolbarActions';
import { profilePictureStyles } from './profilePictureStyles';

export const UserList = () => {
  return (
    <MoonstarList
      actions={<UserToolbarActions />}
      filters={UserFilters}
      filterDefaultValues={{ userIsDisabled: false }}
    >
      <Datagrid bulkActionButtons={<BulkUserDisableAction />} rowClick="edit">
        <ImageField source="pictureUrl" sx={profilePictureStyles} sortable={false} />
        <TextField source="firstName" />
        <TextField source="lastName" />
        <TextField source="phoneNumber" />
        <TextField source="email" />
        <TextField source="jobTitleName" />
        <TextField source="departmentName" />
        <TextField source="regionName" />
        <ReferenceArrayField source="unitIds" reference="units" sortable={false}>
          <SingleFieldList linkType={false}>
            <ChipField source="name" />
          </SingleFieldList>
        </ReferenceArrayField>
        <DateField source="disabledFrom" />
        <TextField source="roleId" />
      </Datagrid>
    </MoonstarList>
  );
};
