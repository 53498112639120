import { OrganizationResource } from '@clerk/types';
import {
  ListItem,
  ListItemButton,
  ListItemAvatar,
  ListSubheader,
  Avatar,
  useTheme,
} from '@mui/material';
import { FC } from 'react';
import { Logout, UserMenu } from 'react-admin';
import { useUserOrganizations } from '../hooks/useUserOrganizations';

export const MoonstarUserMenu = () => {
  return (
    <UserMenu>
      <OrgSwitcher />
      <Logout />
    </UserMenu>
  );
};

const OrgSwitcher = () => {
  const { organizations, activeOrganization, setActiveOrganization } =
    useUserOrganizations();

  if (organizations.length <= 1) {
    // Cannot switch organizations if we're not in multiple organizations
    return null;
  }

  return (
    <>
      <ListSubheader>Company</ListSubheader>
      {organizations.map((membership) => (
        <OrgItem
          activeOrgId={activeOrganization?.organization.id}
          onClick={async () => {
            await setActiveOrganization!({ organization: membership.organization.id });

            // Internally, react-admin uses react-query and caches a bunch of stuff.
            // It also calls the async `getIdentity` function in the authProvider before React has flushed changes, which causes race conditions.
            // A page refresh is the easiest way to invalidate everything and work around these issues, though someone smarter than me can hopefully figure out a better way in the future.
            window.location.reload();
          }}
          organization={membership.organization}
          key={membership.organization.id}
        />
      ))}
      <ListItem divider />
    </>
  );
};

const OrgItem: FC<{
  organization: OrganizationResource;
  activeOrgId: string | undefined;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
}> = (props) => {
  const isActive = props.activeOrgId === props.organization.id;
  const theme = useTheme();

  return (
    <ListItemButton
      sx={{
        minWidth: '12rem',
        background: isActive ? theme.palette.background.default : undefined,
        pointerEvents: isActive ? 'none' : undefined,
      }}
      aria-disabled={isActive}
      onClick={props.onClick}
    >
      <ListItemAvatar>
        <Avatar
          alt={`${props.organization.name} logo`}
          src={props.organization.imageUrl}
        />
      </ListItemAvatar>
      {props.organization.name}
    </ListItemButton>
  );
};
