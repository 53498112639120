export const badgeAwardDateRangeCheck = (
  createdAfter: string,
  createdBefore: string,
  errorDispatch: React.Dispatch<React.SetStateAction<string>>,
) => {
  let errorMessage = '';

  const afterDate = new Date(createdAfter);
  const beforeDate = new Date(createdBefore);

  if (isNaN(afterDate.getTime()) || isNaN(beforeDate.getTime())) {
    errorMessage = 'Invalid date(s) selected.';
  } else if (afterDate > beforeDate) {
    errorMessage = "'Awarded from' cannot be the date after the 'Awarded before'.";
  } else if (afterDate.getTime() === beforeDate.getTime()) {
    errorMessage = "'Awarded from' and 'Awarded before' cannot be the same date.";
  }

  errorDispatch(errorMessage);
};
