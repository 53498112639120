import { RestrictedUser } from '@sendbird/chat';
import { GroupChannel } from '@sendbird/chat/groupChannel';
import { useChannelSettingsContext } from '@sendbird/uikit-react/ChannelSettings/context';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';

const fetchBannedChannelUsers = async (channel: GroupChannel | null) => {
  if (!channel) return [];

  const bannedUserQuery = channel.createBannedUserListQuery();

  let allBannedUsers: RestrictedUser[] = [];
  // We fetch in the loop because of SendBird limit of sending 20 users per request
  while (bannedUserQuery.hasNext) {
    const bannedUsers = await bannedUserQuery.next();
    allBannedUsers = [...allBannedUsers, ...bannedUsers];
  }

  return allBannedUsers;
};

export const useFetchBannedChannelUsers = (
  options?: UseQueryOptions<RestrictedUser[]>,
) => {
  const { channel } = useChannelSettingsContext();

  return useQuery<RestrictedUser[]>({
    queryKey: ['channel-banned', channel],
    queryFn: () => fetchBannedChannelUsers(channel),
    ...options,
  });
};
