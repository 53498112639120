import {
  ChipField,
  Datagrid,
  ImageField,
  Loading,
  ReferenceArrayField,
  ReferenceField,
  SingleFieldList,
  TextField,
  useGetMany,
} from 'react-admin';
import { useParams } from 'react-router-dom';
import { MoonstarList } from '../../Moonstar/MoonstarList';
import { BasicUserFilters } from '../../User/UserFilters';
import { profilePictureStyles } from '../../User/profilePictureStyles';

interface CohortMembership {
  id: number;
  cohortId: number;
  userId: number;
}

export const CohortMembershipList = () => {
  const { cohortId } = useParams<{ cohortId: string }>();
  const numericCohortId = Number(cohortId);

  const { isLoading, error, data } = useGetMany<CohortMembership>('cohortMemberships', {
    ids: [numericCohortId],
    meta: {
      idField: 'cohortId',
    },
  });

  if (isLoading) {
    return <Loading />;
  } else if (error) {
    return <p>ERROR</p>;
  }

  return (
    <MoonstarList
      filters={BasicUserFilters}
      filter={{ id: data?.map((d) => d.userId), userIsDisabled: false }}
      actions={false}
      resource="users"
    >
      <Datagrid bulkActionButtons={false} rowClick="edit">
        <ImageField source="pictureUrl" sx={profilePictureStyles} sortable={false} />
        <TextField source="firstName" />
        <TextField source="lastName" />
        <TextField source="phoneNumber" />
        <TextField source="email" />
        <ReferenceField
          source="jobTitle.id"
          reference="jobTitles"
          link={false}
          label="Job Title"
          sortBy="jobTitle.name"
        >
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField
          source="department.id"
          reference="departments"
          link={false}
          label="Department"
          sortBy="department.name"
        >
          <TextField source="name" label="Department" />
        </ReferenceField>
        <ReferenceField
          source="region.id"
          reference="regions"
          link={false}
          label="Region"
          sortBy="region.Name"
        >
          <TextField source="name" />
        </ReferenceField>
        <ReferenceArrayField source="unitIds" reference="units" sortable={false}>
          <SingleFieldList linkType={false}>
            <ChipField source="name" />
          </SingleFieldList>
        </ReferenceArrayField>
      </Datagrid>
    </MoonstarList>
  );
};
