import { Datagrid, TextField } from 'react-admin';
import { MoonstarList } from '../Moonstar/MoonstarList';
import { MoonstarDatagridBulkActions } from '../Moonstar/MoonstarDatagridBulkActions';

export const JobTitleList = () => (
  <MoonstarList>
    <Datagrid rowClick="edit" bulkActionButtons={<MoonstarDatagridBulkActions />}>
      <TextField source="name" />
    </Datagrid>
  </MoonstarList>
);
