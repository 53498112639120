import { GroupChannel, Member } from '@sendbird/chat/groupChannel';
import { useChannelSettingsContext } from '@sendbird/uikit-react/ChannelSettings/context';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';

const fetchChannelMembers = async (channel: GroupChannel | null) => {
  if (!channel) return [];

  const memberListQuery = channel.createMemberListQuery();

  let allMembers: Member[] = [];
  // We fetch in the loop because of SendBird limit of sending 20 users per request
  while (memberListQuery.hasNext) {
    const members = await memberListQuery.next();
    allMembers = [...allMembers, ...members];
  }

  return allMembers;
};

export const useFetchChannelMembers = (options?: UseQueryOptions<Member[]>) => {
  const { channel } = useChannelSettingsContext();

  return useQuery<Member[]>({
    queryKey: ['channel-members', channel],
    queryFn: () => fetchChannelMembers(channel),

    // Not sure if this will need to be revalidated when the member list changes (such as after an invite)
    staleTime: 60 * 1000, // 1 minute
    ...options,
  });
};
