import { HttpClient } from '../httpClient';

export const fetchAllUsers = (httpClient: HttpClient) => async () => {
  const response = await httpClient.api('/users');

  return response.json as UserDto[];
};

export interface UserDto {
  id: number;
  displayName: string;
  pictureUrl?: string;
  jobTitle: string;
  department: string;
  region: string;
  units: string[];
}
