import { Button, ButtonProps, CircularProgress } from '@mui/material';

type LoadingButtonProps = ButtonProps & { loading: boolean };

export const LoadingButton = ({ loading, children, ...props }: LoadingButtonProps) => {
  return (
    <Button {...props} disabled={loading || props.disabled}>
      {loading ? (
        <div style={{ display: 'flex', alignItems: 'center', gap: 6 }}>
          <CircularProgress size={16} color="inherit" thickness={6} />
          {children}
        </div>
      ) : (
        children
      )}
    </Button>
  );
};
