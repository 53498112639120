import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Typography from '@mui/material/Typography';
import { SxProps, Theme } from '@mui/material/styles';
import { Button } from '@mui/material';

type MoonstarModalAction = {
  text: string;
  action: () => void;
};

type MoonstarModalProps = {
  title: React.ReactNode;
  content: React.ReactNode;
  actions?: MoonstarModalAction[];
  open: boolean;
  onClose: () => void;
  sx?: SxProps<Theme>;
};

export const MoonstarModal = ({
  title,
  content,
  actions,
  open,
  onClose,
  sx,
}: MoonstarModalProps) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          borderRadius: '6px',
          padding: '16px',
          maxWidth: '800px',
          minWidth: '300px',
          width: 'fit-content',
          ...sx,
        },
      }}
    >
      <DialogTitle id="moonstar-modal-title">
        <Typography variant="h6">{title}</Typography>
      </DialogTitle>
      <DialogContent>{content}</DialogContent>
      <DialogActions>
        {actions?.map((a) => (
          <Button key={a.text} onClick={a.action}>
            {a.text}
          </Button>
        ))}
      </DialogActions>
    </Dialog>
  );
};
