import { FC } from 'react';
import { SimpleForm, SimpleFormProps } from 'react-admin';
import { MoonstarEditFormToolbar } from './MoonstarEditFormToolbar';

export const MoonstarEditForm: FC<SimpleFormProps> = (props) => {
  return (
    <SimpleForm mode="onChange" toolbar={<MoonstarEditFormToolbar />} {...props}>
      {props.children}
    </SimpleForm>
  );
};
