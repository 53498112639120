import { useNotify, useTranslate } from 'react-admin';
import { useUserRoleContext } from '../../../../auth/userRoleContext';
import { useCallback, useState } from 'react';
import GroupChannelListHeader from '@sendbird/uikit-react/GroupChannelList/components/GroupChannelListHeader';
import { IconButton } from '@sendbird/uikit-react/ui/Header';
import { IconColors, IconTypes } from '@sendbird/uikit-react/ui/Icon';
import { SelectCreateGroupsModal } from './SelectCreateGroupsModal';

export const GroupChannelListCustomHeader = () => {
  const notification = useNotify();
  const translate = useTranslate();
  const [open, setOpen] = useState(false);
  const { userRole } = useUserRoleContext();

  // Also check roles before opening modal, so we show notification about error before we show modal if there's one
  const handleCreateChannel = useCallback(() => {
    if (userRole === 'Admin' || userRole === 'User') {
      setOpen(true);
    } else {
      notification(translate('groups.badUserRole'), {
        type: 'error',
      });
    }
  }, [userRole]);

  return (
    <>
      <GroupChannelListHeader
        renderRight={() => (
          <IconButton
            type={IconTypes.CREATE}
            color={IconColors.PRIMARY}
            onClick={handleCreateChannel}
          />
        )}
      />
      <SelectCreateGroupsModal open={open} onClose={() => setOpen(false)} />
    </>
  );
};
