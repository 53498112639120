import { HttpClient } from '../httpClient';

export type SendBirdAppId = {
  appId: string;
};

export const fetchSendBirdAppId = (httpClient: HttpClient) => async () => {
  const response = await httpClient.api('/sendbird');

  return response.json as SendBirdAppId;
};
