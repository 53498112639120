import { Grid, Paper, styled } from '@mui/material';
import { FC } from 'react';
import { MoonstarCreate } from '../Moonstar/MoonstarCreate';
import { BasicForm } from './BasicForm';
import { PollForm } from './PollForm';
import { PostCreateProvider, usePostCreateContext } from './PostCreateProvider';
import { PostCreateToolbar } from './PostCreateToolbar';
import { PostPreview } from './PostPreview/PostPreview';
import { RecognitionForm } from './RecognitionForm';

type FormFromTopicProps = {
  formTypeId?: number;
};

const PostCreateContent = () => {
  const { formTypeId } = usePostCreateContext();

  return (
    <Grid container spacing={2}>
      {/* Post creation forms */}
      <Grid item xs={5}>
        <PaperCreateArea>
          <MoonstarCreate redirect="list">
            <PostCreateForm formTypeId={formTypeId} />
          </MoonstarCreate>
        </PaperCreateArea>
      </Grid>

      {/* Preview post */}
      <Grid item xs={7}>
        <PaperPreviewArea elevation={2}>
          <PostPreview postType={formTypeId} />
        </PaperPreviewArea>
      </Grid>
    </Grid>
  );
};

const PostCreateForm: FC<FormFromTopicProps> = ({ formTypeId }) => {
  switch (formTypeId) {
    case 3:
      return <PollForm formToolbar={<PostCreateToolbar />} />;
    case 2:
      return <RecognitionForm formToolbar={<PostCreateToolbar />} />;
    // Anything else needs to be a BasicForm
    default:
      return <BasicForm formToolbar={<PostCreateToolbar />} />;
  }
};

export const PostCreate = () => {
  return (
    <PostCreateProvider>
      <PostCreateContent />
    </PostCreateProvider>
  );
};

const PaperCreateArea = styled('div')(() => ({
  flex: 1,
  height: 'fit-content',
}));

const PaperPreviewArea = styled(Paper)(() => ({
  height: '95vh',
  justifyContent: 'center',
  alignItems: 'center',
  flex: 1,
}));
