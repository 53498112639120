import ChannelSettings from '@sendbird/uikit-react/ChannelSettings';
import { ChannelSettingsProvider } from '@sendbird/uikit-react/ChannelSettings/context';
import { ChannelSettingsContextProps } from '@sendbird/uikit-react/ChannelSettings/context';
import { GroupCustomChannelSettingsUI } from './GroupCustomChannelSettingsUI';

export const GroupCustomChannelSettings = (props: ChannelSettingsContextProps) => {
  return (
    <ChannelSettingsProvider {...props}>
      <GroupCustomChannelSettingsUI />
    </ChannelSettingsProvider>
  );
};
