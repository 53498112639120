import { SvgIcon, SvgIconProps } from '@mui/material';

type PostCommentIconProps = SvgIconProps;

export const PostCommentIcon = (props: PostCommentIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg
        width="18"
        height="18"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M18.5 9.35714C18.5 13.9727 14.6944 17.7143 10 17.7143C8.75228 17.7143 7.56736 17.45 6.5 16.9752C5.81371 16.6699 3.4566 17.7496 2.06069 18.4485C1.6638 18.6473 1.1999 18.2767 1.31172 17.8472C1.74358 16.1881 2.43993 13.2516 2.15144 12.5714C1.73175 11.582 1.49999 10.4962 1.49999 9.35714C1.49999 4.74162 5.30557 1 10 1C14.6944 1 18.5 4.74162 18.5 9.35714Z"
          stroke="#625b71"
          strokeWidth="1.4"
        />
      </svg>
    </SvgIcon>
  );
};
