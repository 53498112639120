import { Member } from '@sendbird/chat/groupChannel';
import { Dispatch, ReactNode, SetStateAction, useCallback, useMemo } from 'react';
import { ChannelUserCardMuted } from './ChannelUserCardMuted';
import { UserCard } from './UserCard';
import { UserDto } from '../../../api/customEndpoints/fetchAllUsers';

export type ChannelUserCardProps = {
  user: UserDto;
  selectedUsers: UserDto[];
  preSelectedMembers?: Member[];
  setSelectedUsers: Dispatch<SetStateAction<UserDto[]>>;
  right?: ReactNode;
  disabled?: boolean;
  isMuted?: boolean;
  isOperator?: boolean;
};
/**
 * @see https://github.com/sendbird/sendbird-uikit-react/blob/d01d225a77e1914b465488d98db386dee7fb643b/src/ui/UserListItem/index.tsx
 */
export const ChannelUserCard = (props: ChannelUserCardProps) => {
  const userIdx = useMemo(
    () => props.selectedUsers.findIndex((u) => u.id === props.user.id),
    [props.selectedUsers, props.user.id],
  );

  const isSelected = userIdx > -1;

  const handleClick = useCallback(() => {
    props.setSelectedUsers(([...draft]) => {
      if (isSelected) {
        draft.splice(userIdx, 1);
      } else {
        draft.push(props.user);
      }
      return draft;
    });
  }, [props, isSelected, userIdx]);

  return (
    <UserCard
      department={props.user.department}
      displayName={props.user.displayName}
      jobTitle={props.user.jobTitle}
      pictureUrl={props.user.pictureUrl}
      units={props.user.units}
      onClick={handleClick}
      right={props.right}
      disabled={props.disabled}
      AvatarOverlay={props.isMuted ? <ChannelUserCardMuted /> : undefined}
    />
  );
};
