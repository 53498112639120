import { FC, ReactNode } from 'react';
import {
  Edit,
  EditProps,
  useGetResourceLabel,
  useResourceContext,
  useTranslate,
} from 'react-admin';
import { Helmet } from 'react-helmet';

export const MoonstarEdit: FC<EditProps & { children: ReactNode }> = (props) => {
  const resourceContext = useResourceContext();
  const getLabel = useGetResourceLabel();
  const translate = useTranslate();

  const label = getLabel(resourceContext as string, 1);
  const edit = translate('ra.action.edit');
  const appName = translate('moonstar.appName');

  return (
    <Edit title={`${appName} / ${label} / ${edit}`} mutationMode="pessimistic" {...props}>
      <>
        <Helmet>
          <title>
            {edit} | {label} | {appName}
          </title>
        </Helmet>
        {props.children}
      </>
    </Edit>
  );
};
