import React from 'react';
import { PostAvatar, PostHeaderDiv, PostMenuAndDateDiv } from './styled';
import { Typography } from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { postPreviewColors } from './PostPreview';

type PostPreviewHeaderProps = {
  currentUserPictureUrl: string;
  currentUserDisplayName: string;
  topicDisplayName: string;
};

export const PostPreviewHeader = (props: PostPreviewHeaderProps) => {
  return (
    <PostHeaderDiv>
      {/* Avatar + user name + post type */}
      <PostAvatar sx={{ width: 48, height: 48 }} src={props.currentUserPictureUrl} />
      <div>
        <Typography>{props.currentUserDisplayName ?? 'You'}</Typography>
        <Typography color={postPreviewColors.primary}>
          {props.topicDisplayName}
        </Typography>
      </div>

      {/* Dots menu + post date */}
      <PostMenuAndDateDiv>
        <MoreHorizIcon />
        <Typography color={postPreviewColors.dateFontColor}>
          {new Date().toLocaleDateString('en-GB', {
            day: 'numeric',
            month: 'long',
            year: 'numeric',
          })}
        </Typography>
      </PostMenuAndDateDiv>
    </PostHeaderDiv>
  );
};
