export const getFileHostUrl = (tenantId: string) => {
  const hostUrl = {
    dev: 'https://files.dev.aws.joinmoonstar.app',
    test: 'https://files.test.aws.joinmoonstar.app',
    prod: 'https://files.prod.aws.joinmoonstar.app',
  };

  if (tenantId === 'dev') {
    return hostUrl.dev;
  } else if (tenantId === 'test' || tenantId === 'e2e') {
    return hostUrl.test;
  } else {
    return hostUrl.prod;
  }
};

export const getApiUrl = (tenantId: string) => {
  const apiUrl = {
    dev: 'https://dev.aws.joinmoonstar.app',
    test: 'https://test.aws.joinmoonstar.app',
    prod: 'https://prod.aws.joinmoonstar.app',
  };

  if (tenantId === 'dev') {
    return apiUrl.dev;
  } else if (tenantId === 'test' || tenantId === 'e2e') {
    return apiUrl.test;
  } else {
    return apiUrl.prod;
  }
};
