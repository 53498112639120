import { convertArrayToCSV } from 'convert-array-to-csv';
import { ResourcesValue } from '../AdminResources';
import { saveAs } from '../../../utils/saveAs';

export type BadgeAward = {
  id: number;
  awardedTo: string;
  awardedToUserId: number;
  awardedToUnit: string;
  badge: string;
  badgeId: number;
  awardedBy: string;
  awardedByUserId: number;
  awardedByUnit: string;
  awardedOn: string;
};

export const createListExporter = (resource: ResourcesValue) => {
  return (badgesAwards: BadgeAward[]) => {
    const mapped = badgesAwards.map((bA: BadgeAward) => {
      return {
        AwardedTo: bA.awardedTo,
        AwardedToUnit: bA.awardedToUnit,
        Badge: bA.badge,
        AwardedBy: bA.awardedBy,
        AwardedByUnit: bA.awardedByUnit,
        AwardedOn: bA.awardedOn,
      };
    });

    const result = convertArrayToCSV(mapped, {
      separator: ',',
    });

    saveAs(result, resource, 'text/csv');
  };
};
