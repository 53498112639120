import {
  AutocompleteInput,
  BooleanInput,
  ReferenceInput,
  SimpleForm,
  TextInput,
  required,
} from 'react-admin';
import { MoonstarCreate } from '../Moonstar/MoonstarCreate';
import { SimpleStack } from '../Moonstar/SimpleStack';
import { MoonstarCreateForm } from '../Moonstar/MoonstarCreateForm';

export const TopicCreate = () => (
  <MoonstarCreate redirect="list">
    <MoonstarCreateForm>
      <SimpleStack>
        <TextInput source="displayName" validate={required()} />
        <ReferenceInput
          source="formTypeId"
          sort={{ field: 'id', order: 'ASC' }}
          reference="formTypes"
        >
          <AutocompleteInput optionText="typeName" validate={required()} />
        </ReferenceInput>
      </SimpleStack>
    </MoonstarCreateForm>
  </MoonstarCreate>
);
