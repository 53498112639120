import { CreateButton, ExportButton, FilterButton, TopToolbar } from 'react-admin';

export const PostToolbarActions = () => {
  return (
    <TopToolbar>
      <FilterButton />
      <ExportButton />
      <CreateButton />
    </TopToolbar>
  );
};
