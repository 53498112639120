import { Datagrid, DateField, TextField } from 'react-admin';
import { MoonstarList } from '../Moonstar/MoonstarList';
import { SmsMessageFilters } from './SmsMessageFilters';
import { SmsMessageToolbarActions } from './SmsMessageToolbarActions';
import { SmsMessageResendAction } from './SmsMessageResendAction';

export const SmsMessageList = () => (
  <MoonstarList actions={<SmsMessageToolbarActions />} filters={SmsMessageFilters}>
    <Datagrid
      bulkActionButtons={<SmsMessageResendAction />}
      isRowSelectable={(record) => record.resendAllowed}
    >
      <TextField source="firstName" />
      <TextField source="lastName" />
      <TextField source="phoneNumber" />
      <TextField source="status" />
      <TextField source="details" sortable={false} />
      <DateField
        source="timestamp"
        showTime
        options={{
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
        }}
      />
    </Datagrid>
  </MoonstarList>
);
