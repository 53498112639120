import { TextInput, required } from 'react-admin';
import { MoonstarCreate } from '../Moonstar/MoonstarCreate';
import { SimpleStack } from '../Moonstar/SimpleStack';
import { MoonstarCreateForm } from '../Moonstar/MoonstarCreateForm';

export const DepartmentCreate = () => {
  return (
    <MoonstarCreate redirect="list">
      <MoonstarCreateForm>
        <SimpleStack>
          <TextInput source="name" validate={required()} />
        </SimpleStack>
      </MoonstarCreateForm>
    </MoonstarCreate>
  );
};
