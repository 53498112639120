import React, { ReactElement, ReactNode } from 'react';
import type { Member } from '@sendbird/chat/groupChannel';
import UserListItem, { UserListItemProps } from '@sendbird/uikit-react/ui/UserListItem';
import useLocalization from '@sendbird/uikit-react/hooks/useLocalization';
import { useChannelSettingsContext } from '@sendbird/uikit-react/ChannelSettings/context';
import Modal from '@sendbird/uikit-react/ui/Modal';
import UserListItemMenu from '@sendbird/uikit-react/ui/UserListItemMenu';
import { useFetchChannelMembers } from '../../../../api/hooks/sendbird/useFetchChannelMembers';
import { CenteredCircularProgress } from '../../../custom/CenteredCircularProgress';

export interface MembersModalProps {
  onCancel(): void;
  renderUserListItem?: (props: UserListItemProps & { index: number }) => ReactNode;
}

export function MembersModal({
  onCancel,
  renderUserListItem = (props) => <UserListItem {...props} />,
}: MembersModalProps): ReactElement {
  const { channel } = useChannelSettingsContext();
  const { stringSet } = useLocalization();

  const { data: members, refetch: refetchMembers, isPending } = useFetchChannelMembers();

  return (
    <div>
      <Modal
        isFullScreenOnMobile
        hideFooter
        onClose={() => onCancel()}
        onSubmit={() => void 0}
        titleText={stringSet.CHANNEL_SETTING__MEMBERS__SEE_ALL_MEMBERS}
      >
        <div className="sendbird-more-members__popup-scroll">
          {isPending ? (
            <CenteredCircularProgress />
          ) : (
            <>
              {(members ?? []).map((member: Member, index) => (
                <React.Fragment key={member.userId}>
                  {renderUserListItem({
                    // NOTE: This `index` is used to display the current user's user item at the top when customizing externally.
                    index,
                    user: member,
                    channel: channel ?? undefined,
                    renderListItemMenu: (props) => (
                      <UserListItemMenu
                        {...props}
                        onToggleOperatorState={() => refetchMembers()}
                        onToggleMuteState={() => refetchMembers()}
                        onToggleBanState={() => refetchMembers()}
                      />
                    ),
                  })}
                </React.Fragment>
              ))}
            </>
          )}
        </div>
      </Modal>
    </div>
  );
}
