// https://sendbird.com/docs/chat/uikit/v3/react/tutorial/apply-brand-color#2-how-to-customize-3-step-2-change-the-property
export const sendBirdColorSet = {
  // Text, important elements (darkest shade)
  '--sendbird-light-primary-500': '#1A5964',
  // Secondary elements
  '--sendbird-light-primary-400': '#21717A',
  // Primary usage (mid-tone shade)
  '--sendbird-light-primary-300': '#28808F',
  // Background highlights
  '--sendbird-light-primary-200': '#58A5B1',
  // Backgrounds (lightest shade)
  '--sendbird-light-primary-100': '#8FC6CF',
};
