import { ResourcesValue } from '../../components/admin/AdminResources';
import { LifecycleHooks } from '../addLifecycleHooks';
import { AdminDataProvider } from '../adminDataProvider';
import { FileHostType } from '../customEndpoints/uploadFile';

export type ReactAdminEditedFile = {
  /**
   * The file name with extension, e.g. 'Invoice-2929-01-06.pdf'
   */
  title: string;
  /**
   * An object URL for the {@link File}, e.g. `'blob:https://example.com/1e67e00e-860d-40a5-89ae-6ab0cbee6273'`
   */
  src: string;
  /**
   * The {@link File} object
   */
  rawFile: File;
};

type ReactAdminUneditedFile = {
  /**
   * the file name with extension, e.g. ‘Invoice-2929-01-06.pdf’
   */
  title: string;
  /**
   * The public URL where the file is hosted
   */
  src: string;
};

type ReactAdminFile = ReactAdminEditedFile | ReactAdminUneditedFile;

export const fileInputPendingUpload = (
  file: ReactAdminFile,
): file is ReactAdminEditedFile => {
  return !!file && 'rawFile' in file;
};

export const handleUpload = async (
  file: ReactAdminFile,
  hostType: FileHostType,
  dataProvider: AdminDataProvider,
): Promise<ReactAdminUneditedFile> => {
  if (!fileInputPendingUpload(file)) {
    return file;
  }

  const newUrl = await dataProvider.uploadFile(file, hostType);
  return {
    src: newUrl,
    title: file.title,
  };
};

/**
 * Lifecycle hooks for the data provider to upload files to the file host
 * @see https://marmelab.com/react-admin/DataProviders.html#sending-files-to-a-third-party-service
 * @see https://marmelab.com/react-admin/withLifecycleCallbacks.html#beforesave
 */
export const fileUploadHooks: LifecycleHooks[] = [
  {
    resource: ResourcesValue.NUDGES,
    beforeSave: async (params, dataProvider) => {
      params.image = await handleUpload(
        params.image,
        'images',
        dataProvider as AdminDataProvider,
      );
      return params;
    },
  },
  {
    resource: ResourcesValue.NUDGE_SLIDES,
    beforeSave: async (params, dataProvider) => {
      params.image = await handleUpload(
        params.image,
        'images',
        dataProvider as AdminDataProvider,
      );

      return params;
    },
  },
];
