import { useDataProvider } from 'react-admin';
import { AdminDataProvider } from '../api/adminDataProvider';
import { useQuery } from '@tanstack/react-query';

export const useFeatureFlags = (options?: { enabled?: boolean }) => {
  const provider = useDataProvider<AdminDataProvider>();

  return useQuery({
    queryKey: ['fetch-feature-flags'],
    queryFn: provider.fetchFeatureFlags,
    staleTime: 1000 * 60,
    ...(options ?? {}),
  });
};
