import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  LineChart,
  Line,
} from 'recharts';
import {
  Card,
  CardContent,
  CardHeader,
  FormControlLabel,
  Switch,
  useTheme,
} from '@mui/material';
import { format } from 'date-fns';
import { sortBy } from '../../../utils/sortBy';
import {
  BadgeAwardsChartParams,
  useBadgesAwardsChartData,
} from '../../../api/hooks/useBadgesAwardsChartData';
import { CenteredCircularProgress } from '../../custom/CenteredCircularProgress';
import { useListFilterContext } from 'react-admin';
import { fillWithEmptyDataForChart } from '../../../utils/fillWithEmptyDataForChart';
import { useMemo, useState } from 'react';

type BadgeAwardsChartProps = {
  title: string;
  name: string;
} & Pick<BadgeAwardsChartParams, 'badgeIdFilter'>;

type BadgeAwardsFilterValues = {
  createdAfter?: string;
  createdBefore?: string;
  badgesId?: string[];
};

export const BadgesAwardsChart = ({ badgeIdFilter, ...props }: BadgeAwardsChartProps) => {
  const {
    moonstar: { charts },
  } = useTheme();
  const { filterValues: untypedFilterValues } = useListFilterContext();
  const [shouldFillEmptyData, setShouldFillEmptyData] = useState(false);

  // Type assertion to add types to filterValues
  const filterValues: BadgeAwardsFilterValues = untypedFilterValues;

  const { data: chartData, isPending } = useBadgesAwardsChartData({
    createdAfter: filterValues.createdAfter,
    createdBefore: filterValues.createdBefore,
    badgeIdFilter: filterValues.badgesId ?? badgeIdFilter,
  });

  const sortedData = useMemo(() => {
    if (!chartData) {
      return [];
    }

    const rawData = shouldFillEmptyData
      ? fillWithEmptyDataForChart(chartData)
      : chartData;

    // Ensure count is always a number and sort by date
    return sortBy(
      rawData.map((entry) => ({
        ...entry,
        count: Number(entry.count),
      })),
      (x) => x.date,
    );
  }, [chartData, shouldFillEmptyData]);

  if (isPending && !filterValues.createdAfter && !filterValues.createdBefore) {
    return <CenteredCircularProgress />;
  }

  return (
    <Card sx={{ boxShadow: 3 }}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <CardHeader title={props.title} />
        <FormControlLabel
          control={
            <Switch
              checked={shouldFillEmptyData}
              onChange={() => setShouldFillEmptyData((prev) => !prev)}
              color="primary"
              sx={{
                '& .MuiSwitch-switchBase.Mui-checked': {
                  color: charts.palette.color1,
                },
              }}
            />
          }
          label="Show Days Without Awards"
          sx={{ padding: 2 }}
        />
      </div>
      <CardContent>
        <ResponsiveContainer width="100%" height={400}>
          <LineChart data={sortedData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey="date"
              tickFormatter={(date) => format(new Date(date), 'MMM dd')}
            />
            <YAxis
              yAxisId="left"
              orientation="left"
              stroke={charts.palette.color1}
              tickFormatter={(value) => Math.round(value).toString()}
              allowDecimals={false}
            />
            <Tooltip />
            <Legend />
            <Line
              yAxisId="left"
              type="monotone"
              dataKey="count"
              name={props.name}
              stroke={charts.palette.color1}
            />
          </LineChart>
        </ResponsiveContainer>
      </CardContent>
    </Card>
  );
};
