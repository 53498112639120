import React, { useEffect, useRef, useState, useCallback, useMemo } from 'react';
import { Button, Box, Typography, styled } from '@mui/material';

const DEFAULT_NO_OF_LINES = 3;

type ExpandableTextProps = {
  children: React.ReactNode;
  style?: React.CSSProperties;
  buttonStyle?: React.CSSProperties;
  noOfLines?: number;
  onShowButtonPress?: () => void;
};

export const ExpandableText = ({
  noOfLines = DEFAULT_NO_OF_LINES,
  buttonStyle,
  onShowButtonPress,
  children,
  style,
}: ExpandableTextProps) => {
  const [hideText, setHideText] = useState(true);
  const [isTruncated, setIsTruncated] = useState(false);
  const textRef = useRef<HTMLDivElement>(null);

  const calculateIsTruncated = useCallback(() => {
    if (!textRef.current) return;

    const element = textRef.current;
    const lineHeight = parseInt(window.getComputedStyle(element).lineHeight, 10);
    const lines = element.scrollHeight / lineHeight;

    if (lines > noOfLines) {
      setIsTruncated(true);
    } else {
      setIsTruncated(false);
    }
  }, [noOfLines]);

  useEffect(() => {
    calculateIsTruncated();
  }, [calculateIsTruncated, hideText, children]);

  useEffect(() => {
    const observer = new MutationObserver(() => {
      calculateIsTruncated();
    });

    if (textRef.current) {
      observer.observe(textRef.current, { childList: true, subtree: true });
    }

    return () => {
      observer.disconnect();
    };
  }, [calculateIsTruncated]);

  const numberOfLines = useMemo(() => {
    return hideText ? noOfLines : 'none';
  }, [hideText, noOfLines]);

  const onShowHideCallback = useCallback(() => {
    onShowButtonPress?.();
    setHideText(!hideText);
  }, [hideText, onShowButtonPress]);

  return (
    <div>
      <Box
        ref={textRef}
        sx={{
          display: '-webkit-box',
          WebkitBoxOrient: 'vertical',
          WebkitLineClamp: numberOfLines,
          overflow: 'hidden',
          whiteSpace: 'pre-wrap',
          ...style,
        }}
      >
        {children}
      </Box>
      {isTruncated && (
        <Box>
          <ShowHideMoreButton
            onClick={onShowHideCallback}
            style={buttonStyle}
            variant="text"
          >
            <Typography variant="inherit" fontWeight="550" color="primary">
              {hideText ? 'Show More' : 'Show Less'}
            </Typography>
          </ShowHideMoreButton>
        </Box>
      )}
    </div>
  );
};

const ShowHideMoreButton = styled(Button)(({ theme }) => ({
  padding: theme.spacing(0),
  minHeight: 'auto',
  minWidth: 'auto',
}));
