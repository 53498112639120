import { FC, ReactNode } from 'react';
import { TopToolbar, CreateButton, ToolbarProps } from 'react-admin';

type MoonstarListActionsProps = {
  left?: ReactNode;
  right?: ReactNode;
  hideCreate?: boolean;
} & ToolbarProps;

export const MoonstarListActions: FC<MoonstarListActionsProps> = ({
  left,
  right,
  children,
  hideCreate = false,
  ...rest
}) => {
  return (
    <TopToolbar {...rest}>
      {left}
      {!hideCreate && <CreateButton />} {right}
      {children}
    </TopToolbar>
  );
};
