import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  Icon,
  Typography,
  useTheme,
} from '@mui/material';
import { useTranslate } from 'react-admin';
import GroupsIcon from '@mui/icons-material/Groups';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';

type ChannelModalCardProps = {
  onClick: () => void;
  cardText: string;
  type: 'GROUP' | 'SUPERGROUP';
};

export const ChannelModalCard = (props: ChannelModalCardProps) => {
  const theme = useTheme();
  const translate = useTranslate();

  const subtitleText =
    props.type === 'GROUP'
      ? translate('groups.groupCreateSubtitle')
      : translate('groups.supergroupCreateSubtitle');

  return (
    <Card square variant="outlined">
      <CardActionArea onClick={props.onClick}>
        <CardContent
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 1,
            padding: 1,
            width: '100%',
            height: 90,
          }}
        >
          <Box
            sx={{
              flexGrow: 1,
              display: 'flex',
              flexDirection: 'row',
              padding: 2,
              alignItems: 'center',
            }}
          >
            <Icon fontSize="large">
              {props.type === 'GROUP' ? (
                <ChatBubbleOutlineIcon fontSize="large" color="primary" />
              ) : (
                <GroupsIcon fontSize="large" color="primary" />
              )}
            </Icon>

            <div
              style={{ display: 'flex', flexDirection: 'column', marginRight: 'auto' }}
            >
              <Typography variant="body1" sx={{ marginLeft: 2 }}>
                {props.cardText}
              </Typography>
              <Typography
                variant="caption"
                sx={{ marginLeft: 2 }}
                color={theme.palette.grey[600]}
              >
                {subtitleText}
              </Typography>
            </div>

            <Icon>
              <ChevronRightIcon color="action" />
            </Icon>
          </Box>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};
