import {
  AutocompleteArrayInput,
  AutocompleteInput,
  DateInput,
  ImageField,
  ReferenceArrayInput,
  ReferenceInput,
  TextInput,
  required,
  useRecordContext,
  useTranslate,
} from 'react-admin';
import { MoonstarEdit } from '../Moonstar/MoonstarEdit';
import { Divider, Stack, Typography } from '@mui/material';
import { SimpleStack } from '../Moonstar/SimpleStack';
import { UserEditFormToolbar } from './UserEditFormToolbar';
import { MoonstarEditForm } from '../Moonstar/MoonstarEditForm';
import { phoneNumber } from '../../../utils/validation';

export const UserEdit = () => {
  const translate = useTranslate();
  return (
    <MoonstarEdit redirect="list">
      <MoonstarEditForm toolbar={<UserEditFormToolbar />}>
        <Stack
          divider={<Divider orientation="vertical" flexItem />}
          flexWrap="wrap"
          direction="row"
          useFlexGap
          spacing={2}
        >
          <SimpleStack>
            <Typography variant="h5" gutterBottom>
              {translate('moonstar.user.edit.section.personalDetails')}
            </Typography>
            <ImageField
              source="pictureUrl"
              title="Profile picture"
              sx={{ '& img': { objectPosition: 'left' } }}
            />
            <TextInput source="firstName" validate={required()} />
            <TextInput source="lastName" validate={required()} />
            <PhoneNumberEdit />
            <TextInput source="email" disabled />
          </SimpleStack>

          <SimpleStack>
            <Typography variant="h5" gutterBottom>
              {translate('moonstar.user.edit.section.companyRelatedInfo')}
            </Typography>
            <ReferenceInput
              source="jobTitleId"
              reference="jobTitles"
              perPage={999}
              sort={{ field: 'name', order: 'ASC' }}
            >
              <AutocompleteInput margin="none" optionText="name" validate={required()} />
            </ReferenceInput>
            <ReferenceInput
              source="departmentId"
              reference="departments"
              perPage={999}
              sort={{ field: 'name', order: 'ASC' }}
            >
              <AutocompleteInput margin="none" optionText="name" validate={required()} />
            </ReferenceInput>
            <ReferenceInput
              source="regionId"
              reference="regions"
              perPage={999}
              sort={{ field: 'name', order: 'ASC' }}
            >
              <AutocompleteInput margin="none" optionText="name" validate={required()} />
            </ReferenceInput>
            <ReferenceArrayInput
              source="unitIds"
              reference="units"
              perPage={999}
              sort={{ field: 'name', order: 'ASC' }}
            >
              <AutocompleteArrayInput
                margin="none"
                optionText="name"
                validate={required()}
              />
            </ReferenceArrayInput>
            <DateInput source="startDate" validate={required()} parse={parseDate} />
          </SimpleStack>

          <SimpleStack>
            <Typography variant="h5" gutterBottom>
              {translate('moonstar.user.edit.section.profileSettings')}
            </Typography>
            <TextInput source="language" disabled={true} />
          </SimpleStack>
        </Stack>
      </MoonstarEditForm>
    </MoonstarEdit>
  );
};

const parseDate = (value: string | number | Date) => {
  const date = new Date(value);

  return isNaN(date.getTime()) ? null : date.toISOString();
};

const PhoneNumberEdit = () => {
  const initialValue = useRecordContext();
  const usesSmsLogin = initialValue?.signInMethod == 'phoneNumber';
  const phoneValidation = usesSmsLogin
    ? [required('moonstar.user.edit.phoneNumber.requiredText'), phoneNumber()]
    : [phoneNumber()];

  return (
    <TextInput
      source="phoneNumber"
      validate={phoneValidation}
      helperText="moonstar.user.edit.phoneNumber.helperText"
    />
  );
};
