import { z } from 'zod';
import {
  AdvancedSettingsSchema,
  AttachmentSchema,
  VisibilitySchema,
} from '../CommonSchemas';
import { MAX_PDF_SIZE } from '../Post/PostSchema';

export const MAX_POLL_TITLE_CHARS = 5000;
export const MIN_POLL_TITLE_CHARS = 2;
export const MAX_POLL_OPTION_CHARS = 150;
export const MIN_POLL_OPTION_CHARS = 2;
export const MAX_POLL_OPTIONS = 10;
export const MIN_POLL_OPTIONS = 2;

export const PollSchema = z
  .object({
    topicId: z.number(),
    title: z
      .string({
        message: `Title field can't be empty.`,
      })
      .trim()
      .max(MAX_POLL_TITLE_CHARS, {
        message: `Title must be at most ${MAX_POLL_TITLE_CHARS} characters long.`,
      })
      .min(MIN_POLL_TITLE_CHARS, {
        message: `Title must be at least ${MIN_POLL_TITLE_CHARS} characters long.`,
      }),
    options: z
      .array(
        z.object({
          text: z
            .string({
              message: `Poll option field can't be empty.`,
            })
            .trim()
            .max(MAX_POLL_OPTION_CHARS, {
              message: `Poll option must be at most ${MAX_POLL_OPTION_CHARS} characters long.`,
            })
            .min(MIN_POLL_OPTION_CHARS, {
              message: `Poll option must be at least ${MIN_POLL_OPTION_CHARS} characters long.`,
            }),
        }),
      )
      .max(MAX_POLL_OPTIONS)
      .min(MIN_POLL_OPTIONS),
    pdf: AttachmentSchema.optional().refine(
      (pdf) => {
        // Validate file size only (no count validation), since we don't allow picking more than one PDF file from `PollForm` level
        if (pdf) {
          return MAX_PDF_SIZE > pdf.rawFile.size;
        }
        return true;
      },
      {
        message: "The PDF you're trying to upload is too big.",
      },
    ),
  })
  .and(VisibilitySchema)
  .and(AdvancedSettingsSchema);

export type Poll = z.infer<typeof PollSchema>;
