import { FC } from 'react';
import { FilterButton, TopToolbar } from 'react-admin';

export const SmsMessageToolbarActions: FC = () => {
  return (
    <TopToolbar>
      <FilterButton />
    </TopToolbar>
  );
};
