import { ReactNode, useEffect, useMemo, useState } from 'react';
import { UserRoleContext, useGetUserRole } from './userRoleContext';
import { useAuthState } from 'react-admin';

export const UserRoleProvider = ({ children }: { children: ReactNode }) => {
  const { isPending, authenticated } = useAuthState();

  const options = {
    enabled: isPending ? false : authenticated,
  };
  const { data: initialUserRole } = useGetUserRole(options);

  const [userRole, setUserRole] = useState('User');

  useEffect(() => {
    if (initialUserRole) {
      setUserRole(initialUserRole);
    }
  }, [initialUserRole]);

  const value = useMemo(() => ({ userRole, setUserRole }), [userRole]);

  return <UserRoleContext.Provider value={value}>{children}</UserRoleContext.Provider>;
};
