export const saveAs = (data: string, filename: string, type?: string) => {
  const blob = new Blob([data], { type: type });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = filename;
  a.click();

  setTimeout(() => {
    window.URL.revokeObjectURL(url);
  }, 0);
};
