import { CircularProgress } from '@mui/material';
import { PostContentDiv } from './styled';
import { useGetCurrentUser } from '../../../../hooks/useGetCurrentUser';
import { PostPreviewContent } from './PostPreviewContent';
import { PostPreviewHeader } from './PostPreviewHeader';
import { PostPreviewFooter } from './PostPreviewFooter';
import { AttachmentsPreview } from './AttachmentsPreview';
import { ReactAdminEditedFile } from '../../../../api/files/fileUpload';

export type BasicPostPreviewData = {
  topicId: number;
  content: string;
  attachments?: { src: string }[];
};

type BasicPostPreviewProps = {
  topicDisplayName: string;
  previewData: BasicPostPreviewData;
};

export const BasicPostPreview = ({
  topicDisplayName,
  previewData,
}: BasicPostPreviewProps) => {
  const {
    data: currentUser,
    isSuccess: isSuccessCurrentUser,
    isPending,
  } = useGetCurrentUser();

  const currentUserDisplayName =
    isSuccessCurrentUser && `${currentUser.firstName} ${currentUser.lastName}`;

  if (isPending) {
    return (
      <CircularProgress
        color="primary"
        style={{ alignSelf: 'center', backgroundColor: 'transparent' }}
      />
    );
  }

  return (
    <>
      <PostPreviewHeader
        currentUserDisplayName={currentUserDisplayName ? currentUserDisplayName : 'You'}
        currentUserPictureUrl={currentUser?.pictureUrl ?? ''}
        topicDisplayName={topicDisplayName}
      />

      {/* Post content */}
      <PostContentDiv>
        <PostPreviewContent content={previewData.content} />
      </PostContentDiv>

      {/* Attachments */}
      {previewData?.attachments && (
        <AttachmentsPreview
          attachments={previewData.attachments as ReactAdminEditedFile[]}
        />
      )}

      {/* Reactions and comments */}
      <PostPreviewFooter />
    </>
  );
};
