/* eslint-disable react/jsx-key */
import { AutocompleteInput, DateInput, ReferenceInput, required } from 'react-admin';

export const PostFilters = [
  <ReferenceInput
    source="topicId"
    sort={{ field: 'id', order: 'ASC' }}
    reference="topics"
  >
    <AutocompleteInput optionText="displayName" validate={required()} />
  </ReferenceInput>,
  <DateInput source="createdAfter" label="Created after" />,
  <DateInput source="createdBefore" label="Created before" />,
];
