/* eslint-disable react/jsx-key */
import { SelectInput } from 'react-admin';

export const SmsMessageFilters = [
  <SelectInput
    source="status"
    choices={[
      { id: 'queued', name: 'Queued' },
      { id: 'sent', name: 'Sent' },
      { id: 'delivered', name: 'Delivered' },
      { id: 'failed', name: 'Failed' },
      { id: 'undelivered', name: 'Undelivered' },
      { id: 'delivery_unknown', name: 'Delivery Unknown' },
    ]}
  />,
];
