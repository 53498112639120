import { Autocomplete, TextField } from '@mui/material';
import { Key } from 'react';

interface MuiAutocompleteBaseProps<T, TRequired extends boolean> {
  isPending?: boolean;
  isError?: boolean;
  label: string;
  required: TRequired;
  getOptionKey?: (option: T) => Key;
  getOptionLabel: (option: T) => string;
  style?: React.CSSProperties;
  options: T[] | undefined;
}

export interface MuiAutocompleteProps<T, TRequired extends boolean>
  extends MuiAutocompleteBaseProps<T, TRequired> {
  onChange: (
    event: React.SyntheticEvent,
    value: TRequired extends true ? NonNullable<T> : T | null,
  ) => void;
  value: T | null | undefined;
}

export function MuiAutocomplete<T, TRequired extends boolean = boolean>(
  props: MuiAutocompleteProps<T, TRequired>,
) {
  return (
    <Autocomplete<T, false, TRequired>
      style={{ maxWidth: '250px', ...(props.style ?? {}) }}
      disableClearable={props.required}
      loading={props.isPending}
      options={props.options ?? []}
      renderInput={(params) => (
        <TextField
          {...params}
          required={props.required}
          error={props.isError}
          label={props.label}
          helperText={
            props.isError &&
            'Something went wrong fetching options. Please try again later.'
          }
        />
      )}
      getOptionLabel={props.getOptionLabel}
      getOptionKey={props.getOptionKey}
      isOptionEqualToValue={
        props.getOptionKey
          ? (a, b) => props.getOptionKey!(a) === props.getOptionKey!(b)
          : undefined
      }
      onChange={props.onChange}
      value={props.value!}
      // Workaround for the fact that Autocomplete doesn't transition well between controlled and uncontrolled states
      // This forces the component to be thrown away and re-rendered when it transitions between controlled / uncontrolled
      key={props.value ? 'controlled' : 'not-controlled'}
    />
  );
}

export interface MuiAutocompleteArrayProps<T, TRequired extends boolean>
  extends MuiAutocompleteBaseProps<T, TRequired> {
  allowDuplicates?: boolean;
  onChange: (event: React.SyntheticEvent, value: T[]) => void;
  value: T[];
}

export function MuiAutocompleteArray<T, TRequired extends boolean = boolean>(
  props: MuiAutocompleteArrayProps<T, TRequired>,
) {
  return (
    <Autocomplete<T, true, TRequired>
      style={{ maxWidth: '250px', ...(props.style ?? {}) }}
      disableClearable={props.required}
      loading={props.isPending}
      options={props.options ?? []}
      renderInput={(params) => (
        <TextField
          {...params}
          required={props.required}
          error={props.isError}
          label={props.label}
          helperText={
            props.isError &&
            'Something went wrong fetching options. Please try again later.'
          }
        />
      )}
      getOptionLabel={props.getOptionLabel}
      getOptionKey={props.getOptionKey}
      isOptionEqualToValue={
        props.getOptionKey
          ? (a, b) => props.getOptionKey!(a) === props.getOptionKey!(b)
          : undefined
      }
      filterSelectedOptions={!props.allowDuplicates}
      onChange={props.onChange}
      value={props.value}
      multiple
    />
  );
}
