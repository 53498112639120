import { SystemStyleObject } from '@mui/system';

/**
 * Grows the last column in a react-admin datagrid
 * @example
 * ```tsx
 * <Datagrid sx={growLastColumn}>
 *   <FullWidthColumn />
 *   <SmallColumn />
 * </Datagrid>
 * ```
 */
export const growLastColumn: SystemStyleObject = {
  '& :last-child.RaDatagrid-rowCell': {
    width: '1',
  },
};

/**
 * Grows the first column in a react-admin datagrid
 * @example
 * ```tsx
 * <Datagrid sx={growFirstColumn}>
 *   <FullWidthColumn />
 *   <SmallColumn />
 * </Datagrid>
 * ```
 */
export const growFirstColumn: SystemStyleObject = {
  '& :first-child.RaDatagrid-rowCell': {
    width: '1',
  },
};
