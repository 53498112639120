import { Member } from '@sendbird/chat/groupChannel';
import { useCallback, useMemo } from 'react';
import { UserDto } from '../../../api/customEndpoints/fetchAllUsers';
import { Checkbox, styled } from '@mui/material';

export const ChannelUserCardCheckbox = (props: {
  user: UserDto;
  selectedUsers: UserDto[];
  preSelectedMembers?: Member[];
}) => {
  const isPreSelected = useMemo(
    () =>
      props.preSelectedMembers?.some((u) => u.userId === props.user.id.toString()) ??
      false,
    [props.preSelectedMembers, props.user.id],
  );
  const userIdx = useMemo(
    () => props.selectedUsers.findIndex((u) => u.id === props.user.id),
    [props.selectedUsers, props.user.id],
  );
  const isSelected = userIdx > -1;

  const handleInviteStatus = useCallback(() => {
    if (isPreSelected) {
      return true;
    }
    if (isSelected) {
      return true;
    } else return false;
  }, [isPreSelected, isSelected]);

  return <Checkbox checked={handleInviteStatus()} disabled={isPreSelected} />;
};
