import { PostsChart } from './PostsChart';
import { PulseChecksChart } from './PulseChecksChart';
import { UsersChart } from './UsersChart';

export const Dashboard = () => (
  <div>
    <PostsChart />
    <br></br>
    <UsersChart />
    <br></br>
    <PulseChecksChart />
  </div>
);
