import { H } from 'highlight.run';
import { useEffect } from 'react';
import { useTenantId } from '../components/OrganizationGuard';

const hightlightIoKey = import.meta.env.VITE_HIGHLIGHTIO_FRONTEND_API;

export const useInitMonitoring = () => {
  const tenantId = useTenantId();

  useEffect(() => {
    if (tenantId === 'dev') return;

    H.init(hightlightIoKey, {
      serviceName: 'web-admin',
      tracingOrigins: true,
      networkRecording: {
        enabled: true,
        recordHeadersAndBody: true,
      },
    });
  }, [tenantId]);
};
