/**
 * Sorts an array in place. This method mutates the array and returns a reference to the same array
 * @param items The collection of items to sort in place
 * @param selector Function used to determine what to sort the elements by
 * @param order Whether to sort items in ascending or descending order
 * @returns A reference to {@link items}
 */
export const sortBy = <T, TKey extends string | number>(
  items: T[],
  // eslint-disable-next-line no-unused-vars
  selector: (item: T) => TKey,
  order: 'ascending' | 'descending' = 'ascending',
) => {
  return items.sort((item1, item2) => {
    const key1 = selector(item1);
    const key2 = selector(item2);

    if (key1 < key2) {
      return order === 'ascending' ? -1 : 1;
    } else if (key1 > key2) {
      return order === 'ascending' ? 1 : -1;
    } else {
      return 0;
    }
  });
};
