import { useMemo } from 'react';
import { FeatureFlagResponse } from '../api/customEndpoints/fetchFeatureFlags';
import { useTenantId } from '../components/OrganizationGuard';

/**
 * Workaround whilst the Grow API is locked for changes. 
 * Once we can change it again, we can remove this hook + just enable it in the flags config in the API instead.
 */
export function useFullVersionWebChatEnabled(
  featureFlags: FeatureFlagResponse | undefined,
) {
  const tenantId = useTenantId();

  return useMemo(() => {
    if (featureFlags?.withFullVersionWebChat) {
      return true;
    }

    switch (tenantId) {
      case 'dev':
      case 'test':
        return true;
      default:
        return false;
    }
  }, [featureFlags, tenantId]);
}
