import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
} from '@mui/material';
import { useUserRoleContext } from '../../../../auth/userRoleContext';
import { ChannelModalCard } from './ChannelModalCard';
import { useCreateChannelContext } from '@sendbird/uikit-react/CreateChannel/context';
import { CHANNEL_TYPE } from './types';
import { useState } from 'react';
import { CreateGroupsModal } from './CreateGroupsModal';
import { useTranslate } from 'react-admin';

export const SelectCreateGroupsModal = (props: DialogProps) => {
  const translate = useTranslate();
  const { userRole } = useUserRoleContext();
  const { setType } = useCreateChannelContext();
  const [openCreateGroups, setOpenCreateGroups] = useState(false);

  const isAdmin = userRole === 'Admin';

  const handleClose = () => {
    props.onClose?.({}, 'escapeKeyDown');
  };

  const handleCreateGroup = () => {
    setType(CHANNEL_TYPE.GROUP);
    handleClose();
    setOpenCreateGroups(true);
  };

  const handleCreateSuperGroup = () => {
    setType(CHANNEL_TYPE.SUPERGROUP);
    handleClose();
    setOpenCreateGroups(true);
  };

  return (
    <>
      <Dialog {...props} fullWidth>
        <>
          <DialogTitle>Create a new channel</DialogTitle>
          <DialogContent style={{ gap: 12 }}>
            <ChannelModalCard onClick={handleCreateGroup} cardText="Group" type="GROUP" />
            {isAdmin && (
              <ChannelModalCard
                onClick={handleCreateSuperGroup}
                cardText="Supergroup"
                type="SUPERGROUP"
              />
            )}
          </DialogContent>
          <DialogActions>
            <Button style={{ marginBottom: 8, marginRight: 8 }} onClick={handleClose}>
              {translate('groups.close')}
            </Button>
          </DialogActions>
        </>
      </Dialog>
      <CreateGroupsModal
        open={openCreateGroups}
        onClose={() => setOpenCreateGroups(false)}
      />
    </>
  );
};
