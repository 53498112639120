import { Button } from '@mui/material';
import React, { FC } from 'react';
import {
  SimpleFormIterator,
  SimpleFormIteratorProps,
  useSimpleFormIterator,
} from 'react-admin';
import AddIcon from '@mui/icons-material/Add';

export const PollOptionFormIterator: FC<SimpleFormIteratorProps> = ({
  children,
  ...props
}) => {
  const AddButton = () => {
    const { add } = useSimpleFormIterator();

    return (
      <Button startIcon={<AddIcon />} variant="text" onClick={add}>
        Add another answer
      </Button>
    );
  };

  return (
    <SimpleFormIterator {...props} addButton={<AddButton />}>
      {children}
    </SimpleFormIterator>
  );
};
