import { styled } from '@mui/material';
import Icon from '@sendbird/uikit-react/ui/Icon';

export const ChannelUserCardMuted = () => {
  return (
    <ChannelUserCardContainer>
      <ChannelUserCardOverlay>
        <Icon height={40} type="MUTE" fillColor="PRIMARY" />
      </ChannelUserCardOverlay>
    </ChannelUserCardContainer>
  );
};

const ChannelUserCardContainer = styled('div')({
  justifyContent: 'center',
  alignItems: 'center',
});

const ChannelUserCardOverlay = styled('div')({
  // TODO: instead of number values use 'theme'
  position: 'absolute',
  bottom: 0,
  right: -14,
  width: 54,
  height: 54,
  zIndex: 1,
  backgroundColor: 'rgba(255,255,255, 0.7)',
  borderRadius: 32,
  justifyContent: 'center',
  alignItems: 'center',
});
