import React, { ReactElement, useEffect, useState, useCallback, ReactNode } from 'react';
import type { Member } from '@sendbird/chat/groupChannel';
import { Role } from '@sendbird/chat';
import UserListItem, { UserListItemProps } from '@sendbird/uikit-react/ui/UserListItem';
import { useChannelSettingsContext } from '@sendbird/uikit-react/ChannelSettings/context';
import { useLocalization } from '@sendbird/uikit-react/hooks/useLocalization';
import UserListItemMenu from '@sendbird/uikit-react/ui/UserListItemMenu';
import Button, { ButtonSizes, ButtonTypes } from '@sendbird/uikit-react/ui/Button';
import { MembersModal } from './MembersModal';
import { InviteUsersModal } from './InviteUsersModal';

interface MemberListProps {
  canInviteUsers: boolean;
  renderUserListItem?: (props: UserListItemProps & { index: number }) => ReactNode;
}
export const MemberList = ({
  canInviteUsers,
  renderUserListItem = (props) => <UserListItem {...props} />,
}: MemberListProps): ReactElement => {
  const [members, setMembers] = useState<Array<Member>>([]);
  const [hasNext, setHasNext] = useState(false);
  const [showAllMembers, setShowAllMembers] = useState(false);
  const [showInviteUsers, setShowInviteUsers] = useState(false);
  const { channel, forceUpdateUI } = useChannelSettingsContext();
  const { stringSet } = useLocalization();

  const refreshList = useCallback(() => {
    if (!channel) {
      setMembers([]);
      return;
    }
    const memberUserListQuery = channel?.createMemberListQuery({
      // Short list of users. If the user wants to see more, they can click the "Show all" button, which presents a modal
      limit: 10,
    });
    memberUserListQuery.next().then((members) => {
      setMembers(members);
      setHasNext(memberUserListQuery.hasNext);
    });
  }, [channel?.url, channel?.createMemberListQuery]);
  useEffect(refreshList, [channel?.url]);

  return (
    <div className="sendbird-channel-settings-member-list">
      {members.map((member, index) => (
        <React.Fragment key={member.userId}>
          {renderUserListItem({
            // NOTE: This `index` is used to display the current user's user item at the top when customizing externally.
            index,
            user: member,
            channel: channel ?? undefined,
            size: 'small',
            avatarSize: '24px',
            renderListItemMenu: (props) => (
              <UserListItemMenu
                {...props}
                onToggleOperatorState={({ newStatus: isOperator }) => {
                  const newMembers = [...members];
                  for (const newMember of newMembers) {
                    if (newMember.userId === member.userId) {
                      newMember.role = isOperator ? Role.OPERATOR : Role.NONE;
                      break;
                    }
                  }
                  setMembers(newMembers);
                }}
                onToggleMuteState={({ newStatus: isMuted }) => {
                  const newMembers = [...members];
                  for (const newMember of newMembers) {
                    if (newMember.userId === member.userId) {
                      newMember.isMuted = isMuted;
                      break;
                    }
                  }
                  setMembers(newMembers);
                }}
                onToggleBanState={() => {
                  setMembers(
                    members.filter(({ userId }) => {
                      return userId !== member.userId;
                    }),
                  );
                }}
              />
            ),
          })}
        </React.Fragment>
      ))}
      <div className="sendbird-channel-settings-accordion__footer">
        {hasNext && (
          <Button
            type={ButtonTypes.SECONDARY}
            size={ButtonSizes.SMALL}
            onClick={() => setShowAllMembers(true)}
          >
            {stringSet.CHANNEL_SETTING__MEMBERS__SEE_ALL_MEMBERS}
          </Button>
        )}
        {canInviteUsers && (
          <Button
            type={ButtonTypes.SECONDARY}
            size={ButtonSizes.SMALL}
            onClick={() => setShowInviteUsers(true)}
          >
            {stringSet.CHANNEL_SETTING__MEMBERS__INVITE_MEMBER}
          </Button>
        )}
      </div>
      {showAllMembers && (
        <MembersModal
          onCancel={() => {
            setShowAllMembers(false);
            refreshList();
            forceUpdateUI();
          }}
          renderUserListItem={renderUserListItem}
        />
      )}
      <InviteUsersModal
        open={showInviteUsers}
        onCancel={() => setShowInviteUsers(false)}
        onSubmit={() => {
          setShowInviteUsers(false);
          refreshList();
          forceUpdateUI();
        }}
      />
    </div>
  );
};
