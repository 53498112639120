import {
  AutocompleteArrayInput,
  DateTimeInput,
  ImageField,
  ImageInput,
  NumberInput,
  ReferenceArrayInput,
  TextInput,
  required,
} from 'react-admin';
import { MoonstarCreate } from '../Moonstar/MoonstarCreate';
import { SimpleStack } from '../Moonstar/SimpleStack';
import { MoonstarCreateForm } from '../Moonstar/MoonstarCreateForm';

export const NudgeCreate = () => (
  <MoonstarCreate redirect="list">
    <MoonstarCreateForm>
      <SimpleStack>
        <ImageInput
          source="image"
          accept={{ 'image/*': ['.jpg', '.jpeg', '.png', '.gif'] }}
          sx={{ alignItems: 'start' }}
        >
          <ImageField source="src" title="title" />
        </ImageInput>
        <TextInput source="title" validate={required()} />
        <ReferenceArrayInput
          source="categoryIds"
          reference="nudgeCategories"
          perPage={999}
          sort={{ field: 'name', order: 'ASC' }}
        >
          <AutocompleteArrayInput margin="none" optionText="name" />
        </ReferenceArrayInput>
        <ReferenceArrayInput
          source="cohortIds"
          reference="cohorts"
          perPage={999}
          sort={{ field: 'name', order: 'ASC' }}
        >
          <AutocompleteArrayInput
            margin="none"
            optionText="name"
            helperText="Select which cohorts to show this nudge to"
          />
        </ReferenceArrayInput>
        <TextInput source="description" />
        <NumberInput source="duration" step={1} min={1} validate={required()} />
        <TextInput source="hintDescription" validate={required()} />
        <DateTimeInput
          defaultValue={new Date()}
          source="hintValidTillDateTime"
          validate={required()}
        />
      </SimpleStack>
    </MoonstarCreateForm>
  </MoonstarCreate>
);
