import { z } from 'zod';
import {
  AdvancedSettingsSchema,
  ContentSchema,
  TaggedUsersSchema,
  VisibilitySchema,
  AttachmentSchema,
} from '../CommonSchemas';

export const MAX_NUMBER_OF_DOCUMENTS = 1;
export const MAX_NUMBER_OF_VIDEOS = 1;
export const MAX_NUMBER_OF_IMAGES = 10;

export const MAX_IMAGE_SIZE = 5_000_000; // 5Mb
export const MAX_VIDEO_SIZE = 1_000_000_000; // 1Gb
export const MAX_PDF_SIZE = 5_000_000; // 5Mb

export const PostSchema = z
  .object({
    topicId: z.number(),
    attachments: z.array(AttachmentSchema).superRefine((attachments, ctx) => {
      let pdfCount = 0;
      let videoCount = 0;
      let imageCount = 0;

      // eslint-disable-next-line no-unused-vars
      for (const [index, attachment] of attachments.entries()) {
        const file = attachment.rawFile;

        // Validate file size
        if (file.type.includes('image') && file.size > MAX_IMAGE_SIZE) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: `The image ${file.name} you're trying to upload is too big. (Max size: 5 MB)`,
          });
        } else if (file.type.includes('video') && file.size > MAX_VIDEO_SIZE) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: `The video ${file.name} you're trying to upload is too big. (Max size: 1 GB)`,
          });
        } else if (file.type.includes('pdf') && file.size > MAX_PDF_SIZE) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: `The PDF ${file.name} you're trying to upload is too big. (Max size: 5 MB)`,
          });
        }

        // Validate file type counts
        if (file.type.includes('pdf')) {
          pdfCount++;
          if (pdfCount > MAX_NUMBER_OF_DOCUMENTS) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: `Only ${MAX_NUMBER_OF_DOCUMENTS} PDF is allowed.`,
            });
          }
        } else if (file.type.includes('video')) {
          videoCount++;
          if (videoCount > MAX_NUMBER_OF_VIDEOS) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: `Only ${MAX_NUMBER_OF_VIDEOS} video is allowed.`,
            });
          }
        } else if (file.type.includes('image')) {
          imageCount++;
          if (imageCount > MAX_NUMBER_OF_IMAGES) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: `Only up to ${MAX_NUMBER_OF_IMAGES} images are allowed.`,
            });
          }
        }
      }
    }),
  })
  .and(ContentSchema)
  .and(TaggedUsersSchema)
  .and(AdvancedSettingsSchema)
  .and(VisibilitySchema);

export type Post = z.infer<typeof PostSchema>;
