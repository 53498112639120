import { FC } from 'react';
import { SaveButton, Toolbar } from 'react-admin';

export const UserEditFormToolbar: FC = ({ ...props }) => {
  return (
    <Toolbar {...props}>
      <SaveButton />
    </Toolbar>
  );
};
