import GroupChannelUI from '@sendbird/uikit-react/GroupChannel/components/GroupChannelUI';
import { useGroupChannelContext } from '@sendbird/uikit-react/GroupChannel/context';
import MessageContent from '@sendbird/uikit-react/ui/MessageContent';
import React, { useCallback, useEffect } from 'react';

import { MessageCustomMenu } from './MessageCustomMenu/MessageCustomMenu';

export const GroupCustomChannelUI: React.FC = () => {
  const context = useGroupChannelContext();

  const handleEvent = useCallback(
    (event: DragEvent | ClipboardEvent) => {
      event.preventDefault();
      let files: FileList | null = null;

      if (event.type === 'drop' && event instanceof DragEvent) {
        files = event.dataTransfer?.files || null;
      } else if (event.type === 'paste' && event instanceof ClipboardEvent) {
        files = event.clipboardData?.files || null;
      }

      if (files && files.length > 0) {
        const file = files[0];
        context.sendFileMessage({ file });
      }
    },
    [context],
  );

  useEffect(() => {
    const preventDefault = (event: Event) => event.preventDefault();

    window.addEventListener('dragover', preventDefault);
    window.addEventListener('drop', handleEvent);
    window.addEventListener('paste', handleEvent);

    return () => {
      window.removeEventListener('dragover', preventDefault);
      window.removeEventListener('drop', handleEvent);
      window.removeEventListener('paste', handleEvent);
    };
  }, [handleEvent]);

  return (
    <GroupChannelUI
      renderMessageContent={(props) => (
        <MessageContent
          {...props}
          renderMessageMenu={(menuProps) => <MessageCustomMenu {...menuProps} />}
        />
      )}
    />
  );
};
