import { useRecordContext } from 'react-admin';
import { User } from '../User/types';
import { Avatar, Box, styled, Typography } from '@mui/material';

export const UserOptionRenderer = () => {
  const record = useRecordContext<User>();

  if (!record) {
    return null;
  }

  return (
    <UserOptionContainer>
      <UserOptionAvatar src={record.pictureUrl} />
      <Typography>{`${record.firstName} ${record.lastName}`}</Typography>
    </UserOptionContainer>
  );
};

const UserOptionContainer = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
}));

const UserOptionAvatar = styled(Avatar)(({ theme }) => ({
  marginRight: theme.spacing(1.5),
}));
