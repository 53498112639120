import { Datagrid, TextField } from 'react-admin';
import { MoonstarList } from '../Moonstar/MoonstarList';

export const NudgeCategoryList = () => (
  <>
    <MoonstarList>
      <Datagrid rowClick="edit" bulkActionButtons={false}>
        <TextField source="name" />
      </Datagrid>
    </MoonstarList>
  </>
);
