import { FC } from 'react';
import { FilterButton, TopToolbar } from 'react-admin';
import { ImportUsersWithUIButton } from './ImportUsersWithUIButton';
import { ExportUserTemplateButton } from './ExportUserTemplateButton';
import { useFeatureFlags } from '../../FeatureFlagGuard';
import { ImportUsersWithoutUIButton } from './ImportUsersWithoutUIButton';

export const UserToolbarActions: FC = () => {
  const { data: featureFlags, isPending } = useFeatureFlags();

  if (isPending) {
    return null;
  }

  return (
    <TopToolbar>
      <FilterButton />
      {featureFlags?.withNewOnboarding ? (
        <ImportUsersWithUIButton />
      ) : (
        <ImportUsersWithoutUIButton />
      )}
      <ExportUserTemplateButton />
    </TopToolbar>
  );
};
