import { FC, ReactNode } from 'react';
import {
  Create,
  CreateProps,
  useGetResourceLabel,
  useResourceContext,
  useTranslate,
} from 'react-admin';
import { Helmet } from 'react-helmet';

export const MoonstarCreate: FC<CreateProps & { children: ReactNode }> = (props) => {
  const resourceContext = useResourceContext();
  const getLabel = useGetResourceLabel();
  const translate = useTranslate();

  const label = getLabel(resourceContext as string, 1);
  const create = translate('ra.action.create');
  const appName = translate('moonstar.appName');

  return (
    <Create title={`${appName} / ${label} / ${create}`} {...props}>
      <Helmet>
        <title>
          {create} | {label} | {appName}
        </title>
      </Helmet>
      {props.children}
    </Create>
  );
};
