import {
  CreateButton,
  Datagrid,
  ImageField,
  TextField,
  TopToolbar,
  useListContext,
} from 'react-admin';
import { useParams } from 'react-router-dom';
import { Alert, AlertTitle, Stack } from '@mui/material';
import { MoonstarList } from '../../Moonstar/MoonstarList';
import { FC } from 'react';
import { shrinkFirstColumn } from '../../../../utils/shrinkLastColumn';
import { MoonstarListEmpty } from '../../Moonstar/MoonstarListEmpty';

export const NudgeSlideList = () => {
  const { nudgeId } = useParams();

  return (
    <>
      <Stack spacing={2} sx={{ mt: '1em' }}>
        <Alert severity="success">You can have up to 15 slides per nudge</Alert>
        <Alert severity="warning">
          <AlertTitle>
            We do not currently support changing the order of slides
          </AlertTitle>
          Please create / edit / delete your slides to get them into the order you need.
          Please reach out to us if you need any support with this. Thank you for your
          patience
        </Alert>
      </Stack>
      <MoonstarList
        actions={<NudgeSlideListActions />}
        filter={{ nudgeId }}
        empty={
          <>
            <MoonstarListEmpty createTo="create" hasCreate />
          </>
        }
      >
        <Datagrid
          bulkActionButtons={false}
          rowClick={(id) => `${id}`}
          sx={shrinkFirstColumn}
        >
          <ImageField source="image.src" title="image.title" />
          <TextField source="content" />
        </Datagrid>
      </MoonstarList>
    </>
  );
};

const NudgeSlideListActions: FC = () => {
  const ctx = useListContext();

  if (!ctx.data || typeof ctx.total !== 'number' || ctx.total >= 15) {
    return <></>;
  } else {
    return (
      <TopToolbar>
        <CreateButton to="create" />
      </TopToolbar>
    );
  }
};
