import {
  Alert,
  Avatar,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useState } from 'react';
import { useNotify, useTranslate } from 'react-admin';
import { useForwardMessage } from '../../../../../api/hooks/sendbird/useForwardMessage';
import { useForwardMessageContext } from '../../ForwardMessageProvider';

export const ForwardMessageModal = () => {
  const theme = useTheme();
  const notify = useNotify();
  const translate = useTranslate();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const shareMessageContext = useForwardMessageContext();
  const [selectedChannelsUrls, setSelectedChannelsUrls] = useState<string[]>([]);
  const { message, groupChannels } = shareMessageContext;
  const { mutate: forwardMessage, isPending } = useForwardMessage();

  const handleClose = () => {
    shareMessageContext.setShowModal(false);
  };

  const handleToggle = (url: string) => {
    if (selectedChannelsUrls.includes(url)) {
      setSelectedChannelsUrls(
        selectedChannelsUrls.filter((selectedUrl) => selectedUrl !== url),
      );
    } else {
      setSelectedChannelsUrls([...selectedChannelsUrls, url]);
    }
  };

  const handleShare = async () => {
    const groupsToShare = groupChannels?.filter((channel) =>
      selectedChannelsUrls.includes(channel.url),
    );
    if (!groupsToShare?.length || !message) return;

    forwardMessage(
      { message, channels: groupsToShare },
      {
        onSuccess: () => {
          setSelectedChannelsUrls([]);
          handleClose();
          notify(
            <Alert severity="success">{translate('groups.messageForwarded')}</Alert>,
            {
              autoHideDuration: 5000,
            },
          );
        },
      },
    );
  };

  return (
    <Dialog
      fullScreen={fullScreen}
      open={shareMessageContext.showModal}
      onClose={handleClose}
      scroll="paper"
    >
      <DialogTitle>{translate('groups.messageForwardTitle')}</DialogTitle>
      <DialogContent dividers>
        {groupChannels?.map((channel) => {
          if (channel.url === message?.channelUrl) {
            return null;
          }
          return (
            <ListItem key={channel.url} disablePadding>
              <ListItemButton
                onClick={() => handleToggle(channel.url)}
                dense
                disabled={isPending}
              >
                <ListItemAvatar>
                  <Avatar alt={`Channel: ${channel.name}`} src={channel.coverUrl} />
                </ListItemAvatar>
                <ListItemText primary={channel.name} />

                <ListItemIcon>
                  <Checkbox
                    edge="end"
                    checked={selectedChannelsUrls.includes(channel.url)}
                    tabIndex={-1}
                    disableRipple
                  />
                </ListItemIcon>
              </ListItemButton>
            </ListItem>
          );
        })}
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleClose} disabled={isPending}>
          {translate('groups.cancel')}
        </Button>
        <Box sx={{ m: 1, position: 'relative' }}>
          <Button
            onClick={handleShare}
            autoFocus
            disabled={!selectedChannelsUrls.length || isPending}
          >
            {translate('groups.forward')}{' '}
            {selectedChannelsUrls.length > 0 ? `(${selectedChannelsUrls.length})` : ''}
          </Button>
          {isPending && (
            <CircularProgress
              size={24}
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                marginTop: '-12px',
                marginLeft: '-12px',
              }}
            />
          )}
        </Box>
      </DialogActions>
    </Dialog>
  );
};
