import { FC, useCallback } from 'react';
import { Button, useTranslate } from 'react-admin';
import { Tooltip } from '@mui/material';
import GetAppIcon from '@mui/icons-material/GetApp';
import { saveAs } from '../../../utils/saveAs';
import { convertArrayToCSV } from 'convert-array-to-csv';
import { UserCsvRow } from './types';

export const ExportUserTemplateButton: FC = () => {
  const translate = useTranslate();
  const label = translate('moonstar.user.exportTemplate.tooltip');

  const onClick = useCallback(() => {
    const content: UserCsvRow[] = [
      {
        firstName: 'John',
        lastName: 'Doe',
        phoneNumber: '+40123456789',
        startDate: '01/08/2024',
        department: 'HR',
        jobTitle: 'Manager',
        region: 'UK',
        unit: 'London',
        languageCountryCode: 'en',
      },
    ];
    const result = convertArrayToCSV(content, {
      separator: ',',
    });
    saveAs(result, 'import-users.csv', 'text/csv');
  }, []);

  return (
    <Tooltip title={label}>
      <div>
        <Button label="moonstar.user.exportTemplate.button" onClick={onClick}>
          <GetAppIcon style={{ fontSize: '20' }} />
        </Button>
      </div>
    </Tooltip>
  );
};
