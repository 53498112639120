import { MutableRefObject, useLayoutEffect, useMemo, useReducer } from 'react';
import { UserDto } from '../../../api/customEndpoints/fetchAllUsers';
import { useGetAllUsers } from '../../../hooks/useGetAllUsers';
import { useUserSearch } from './useUserSearch';
import { useVirtualizer } from '@tanstack/react-virtual';
import { Error, useTranslate } from 'react-admin';
import { Typography } from '@mui/material';
import { CenteredCircularProgress } from '../../custom/CenteredCircularProgress';

export type User = UserDto & { userId: string };

type UserListProps = {
  searchTerm: string;
  renderUser: (user: User, index: number) => JSX.Element;
  containerRef: MutableRefObject<HTMLDivElement | null>;
  userIdsFilter?: number[];
  loading?: boolean;
};

/**
 * Renders a list of channel users.
 * @param searchTerm - The search term used to filter the users.
 * @param userIds - The userIds to display on the list. If this prop isn't specified, all users are displayed.
 */
export const UserList = ({
  containerRef,
  searchTerm,
  renderUser,
  userIdsFilter,
}: UserListProps) => {
  const {
    data: growUsers,
    isPending: isPendingUsers,
    isError: isErrorUsers,
    error: errorUsers,
    refetch,
  } = useGetAllUsers();
  const users = useMemo(() => {
    let res = growUsers;
    if (!res) return [];

    if (userIdsFilter) {
      res = res.filter((gu) => userIdsFilter.includes(gu.id));
    }

    return res.map((u) => ({ ...u, userId: `${u.id}` }));
  }, [growUsers, userIdsFilter]);

  const filterUsers = useUserSearch(users);

  const filteredUsers = useMemo(() => filterUsers(searchTerm), [searchTerm, filterUsers]);

  const rowVirtualizer = useVirtualizer({
    count: filteredUsers.length,
    getScrollElement: () => containerRef.current,
    estimateSize: () => 70,
    overscan: 5,
  });

  const [, forceUpdate] = useReducer((v) => v + 1, 0);
  useLayoutEffect(() => {
    // Force component to render twice initially, so that the `containerRef` has a chance to render and be set, and therefore the virtualizer can update accordingly
    forceUpdate();
  }, []);

  const t = useTranslate();

  if (isPendingUsers) {
    return <CenteredCircularProgress />;
  } else if (isErrorUsers) {
    return <Error resetErrorBoundary={refetch} error={errorUsers} />;
  } else if (growUsers.length === 0) {
    return (
      <Typography sx={{ paddingX: 2, textAlign: 'center' }}>
        {t('ra.navigation.no_results')}
      </Typography>
    );
  }

  return (
    <div style={{ height: rowVirtualizer.getTotalSize(), position: 'relative' }}>
      {rowVirtualizer.getVirtualItems().map((u) => (
        <div
          key={u.index}
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: u.size,
            transform: `translateY(${u.start}px)`,
          }}
        >
          {renderUser(filteredUsers[u.index], u.index)}
        </div>
      ))}
    </div>
  );
};
