/* eslint-disable no-unused-vars */
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Typography,
  Card,
  CardHeader,
  CardContent,
  styled,
} from '@mui/material';
import { usePulseQuestionsChartData } from '../../../api/hooks/usePulseQuestionsChartData';
import { sortBy } from '../../../utils/sortBy';

const StackedBar = (props: {
  items: { type: number; percentage: number }[];
  colors: { [key: number]: string };
}) => {
  return (
    <Box display="flex" height={30} width="100%" borderRadius={2} overflow="hidden">
      {props.items.map((item, index) => (
        <AnswerBox
          key={index}
          width={`${item.percentage}%`}
          bgcolor={props.colors[item.type]}
        >
          <PercentageText variant="body2">{item.percentage}%</PercentageText>
        </AnswerBox>
      ))}
    </Box>
  );
};

const Legend = () => (
  <Box
    display="flex"
    alignItems="center"
    justifyContent="center"
    sx={{
      padding: 1,
      borderRadius: 2,
    }}
  >
    <Box display="flex" alignItems="center" mr={2}>
      <Box
        width={20}
        height={20}
        bgcolor={answerColors[AnswerType.StronglyAgree]}
        mr={1}
      />
      <Typography variant="body2">Agree</Typography>
    </Box>
    <Box display="flex" alignItems="center" mr={2}>
      <Box width={20} height={20} bgcolor={answerColors[AnswerType.Undecided]} mr={1} />
      <Typography variant="body2">Undecided</Typography>
    </Box>
    <Box display="flex" alignItems="center">
      <Box
        width={20}
        height={20}
        bgcolor={answerColors[AnswerType.StronglyDisagree]}
        mr={1}
      />
      <Typography variant="body2">Disagree</Typography>
    </Box>
  </Box>
);

export const PulseChecksChart = () => {
  const { data: questions, isPending } = usePulseQuestionsChartData();

  if (isPending || !questions) {
    return <p>Loading...</p>;
  } else {
    const data = questions.map((q) => {
      const positive = q.answers.filter(
        (a) => a.type == AnswerType.Agree || a.type == AnswerType.StronglyAgree,
      );

      const negative = q.answers.filter(
        (a) => a.type == AnswerType.Disagree || a.type == AnswerType.StronglyDisagree,
      );

      const neutral = q.answers.filter((a) => a.type == AnswerType.Undecided);

      const answers = [
        {
          type: AnswerType.StronglyAgree,
          count: positive.reduce((sum, answer) => sum + answer.count, 0),
        },
        {
          type: AnswerType.StronglyDisagree,
          count: negative.reduce((sum, answer) => sum + answer.count, 0),
        },
        {
          type: AnswerType.Undecided,
          count: neutral[0]?.count,
        },
      ];

      const filtered = answers.filter((a) => a.count > 0);

      return {
        id: q.id,
        text: q.text,
        total: q.total,
        answers: sortBy(filtered, (x) => x.type, 'descending'),
      };
    });

    return (
      <Card sx={{ boxShadow: 3 }}>
        <CardHeader
          title="Pulse questions"
          action={<Legend />}
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        />
        <CardContent>
          <TableContainer>
            <Table aria-label="engagement table">
              <TableHead>
                <TableRow>
                  <TableCell>Question</TableCell>
                  <TableCell>Answers</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((item) => (
                  <TableRow key={item.id}>
                    <TableCell>{item.text}</TableCell>
                    <TableCell style={{ width: '50%' }}>
                      <Box display="flex" flexDirection="column">
                        <StackedBar
                          items={item.answers.map((a) => ({
                            type: a.type,
                            percentage: Math.round((a.count / item.total) * 1000) / 10,
                          }))}
                          colors={answerColors}
                        />
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
      </Card>
    );
  }
};

enum AnswerType {
  StronglyDisagree = 1,
  Disagree = 2,
  Undecided = 3,
  Agree = 4,
  StronglyAgree = 5,
}

const answerColors: { [key in AnswerType]: string } = {
  [AnswerType.StronglyDisagree]: '#d16b77',
  [AnswerType.Disagree]: '#f7b7bd',
  [AnswerType.Undecided]: '#EBE9ED',
  [AnswerType.Agree]: '#93D4BF',
  [AnswerType.StronglyAgree]: '#28808F',
};

const AnswerBox = styled(Box)(() => ({
  height: '100%',
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const PercentageText = styled(Typography)(() => ({
  color: 'white',
  fontWeight: 'bold',
  textShadow: '1px 1px 2px rgba(0, 0, 0, 0.5)',
}));
