import { IconButton, IconButtonProps, styled } from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';

export const SettingsIconButton = (props: IconButtonProps) => {
  return (
    <SettingsButton size="large" {...props}>
      <SettingsIcon />
    </SettingsButton>
  );
};

const SettingsButton = styled(IconButton)(({ theme }) => ({
  margin: 6,
  color: theme.palette.primary.main,
}));
