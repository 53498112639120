import { TextInput, required } from 'react-admin';
import { MoonstarEdit } from '../Moonstar/MoonstarEdit';
import { SimpleStack } from '../Moonstar/SimpleStack';
import { MoonstarEditForm } from '../Moonstar/MoonstarEditForm';

export const DepartmentEdit = () => {
  return (
    <MoonstarEdit redirect="list">
      <MoonstarEditForm>
        <SimpleStack>
          <TextInput source="name" validate={required()} />
        </SimpleStack>
      </MoonstarEditForm>
    </MoonstarEdit>
  );
};
