import { useDataProvider } from 'react-admin';
import { AdminDataProvider } from '../adminDataProvider';
import { HttpClient } from '../httpClient';
import { useQuery, UseQueryOptions, QueryKey } from '@tanstack/react-query';

export const getPulseQuestionsChartData = (httpClient: HttpClient) => async () => {
  const response = await httpClient.api('/admin/pulse/chart', {
    method: 'GET',
  });

  return response.json as Entry[];
};

export interface Entry {
  id: number;
  text: string;
  total: number;
  answers: {
    type: number;
    count: number;
  }[];
}

export const usePulseQuestionsChartData = (
  options?: UseQueryOptions<Entry[], Error, Entry[], QueryKey>,
) => {
  const dataProvider = useDataProvider<AdminDataProvider>();

  return useQuery<Entry[]>({
    queryKey: ['getPulseQuestionsChartData'],
    queryFn: () => dataProvider.getPulseQuestionsChartData(),
    staleTime: 1000 * 60,
    ...options,
  });
};
