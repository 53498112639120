import { Box, CircularProgress, styled, Typography } from '@mui/material';
import { MOONSTAR_THEME } from '../theme';

type AppLoadingProps = {
  isError?: boolean;
};

export const AppLoading = ({ isError }: AppLoadingProps) => {
  if (isError) {
    return (
      <LoadingBox>
        <div>
          <Typography sx={{ fontWeight: 'bold' }}>Error loading app.</Typography>
          <Typography>Please contact Moonstar support if problem persists.</Typography>
        </div>
      </LoadingBox>
    );
  }

  return (
    <LoadingBox>
      <LoadingIndicator size={64} />
    </LoadingBox>
  );
};

const LoadingBox = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100vh',
  textAlign: 'center',
}));

const LoadingIndicator = styled(CircularProgress)(() => ({
  color: MOONSTAR_THEME.palette.primary.main,
}));
