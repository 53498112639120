import { createContext, Dispatch, SetStateAction, useContext } from 'react';
import { useDataProvider } from 'react-admin';
import { AdminDataProvider } from '../api/adminDataProvider';
import { useQuery } from '@tanstack/react-query';

export const useGetUserRole = (options?: { enabled?: boolean }) => {
  const provider = useDataProvider<AdminDataProvider>();

  return useQuery({
    queryKey: ['fetch-user-role'],
    queryFn: provider.fetchUserRole,
    staleTime: 1000 * 60,
    ...(options ?? {}),
  });
};

interface UserRoleContextProps {
  userRole: string;
  setUserRole: Dispatch<SetStateAction<string>>;
}

export const UserRoleContext = createContext<UserRoleContextProps>({
  userRole: 'User',
  setUserRole: () => {},
});

export const useUserRoleContext = () => {
  const context = useContext(UserRoleContext);
  if (!context) {
    throw new Error('useUserRoleContext must be used within a UserRoleProvider');
  }
  return context;
};
