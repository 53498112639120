import { TextInput, required } from 'react-admin';
import { MoonstarEdit } from '../Moonstar/MoonstarEdit';
import { MoonstarEditForm } from '../Moonstar/MoonstarEditForm';
import { SimpleStack } from '../Moonstar/SimpleStack';

export const UnitEdit = () => (
  <MoonstarEdit redirect="list">
    <MoonstarEditForm>
      <SimpleStack>
        <TextInput source="name" validate={required()} />
      </SimpleStack>
    </MoonstarEditForm>
  </MoonstarEdit>
);
