import { Datagrid, ReferenceField, TextField } from 'react-admin';
import { MoonstarList } from '../Moonstar/MoonstarList';
import { MoonstarDatagridBulkActions } from '../Moonstar/MoonstarDatagridBulkActions';

export const TopicList = () => (
  <MoonstarList>
    <Datagrid rowClick="edit" bulkActionButtons={<MoonstarDatagridBulkActions />}>
      <TextField source="displayName" />
      <ReferenceField
        source="formTypeId"
        reference="formTypes"
        link={false}
        sortBy="formtype.displayName"
      >
        <TextField source="typeName" />
      </ReferenceField>
    </Datagrid>
  </MoonstarList>
);
