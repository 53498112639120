import { useClerk, useSession, useOrganization } from '@clerk/clerk-react';
import { useMemo } from 'react';
import { UserIdentity } from 'react-admin';
import { addProviderLifecycleHooks } from '../api/addLifecycleHooks';
import { createAdminDataProvider } from '../api/adminDataProvider';
import { useGetCurrentUser } from '../hooks/useGetCurrentUser';
import { useHttpClient } from './useHttpClient';

export const useReactAdminProviders = () => {
  const { signOut, openSignIn } = useClerk();
  const { session } = useSession();
  const { organization } = useOrganization();
  const client = useHttpClient();

  const dataProvider = useMemo(() => {
    const provider = createAdminDataProvider(client);
    return addProviderLifecycleHooks(provider);
  }, [client]);

  const {
    data: user,
    isLoading,
    isError,
  } = useGetCurrentUser({
    queryFn: dataProvider.fetchCurrentUser,
  });

  const authProvider = {
    checkError: () => Promise.resolve(),
    checkAuth: () => {
      if (session) {
        return Promise.resolve();
      } else {
        return Promise.reject({ message: 'Not authenticated' });
      }
    },
    login: () => {
      openSignIn();
      return Promise.resolve();
    },
    logout: () => signOut(),
    getIdentity: async (): Promise<UserIdentity> => {
      if (user) {
        let displayName = user.displayName;
        if (organization) {
          displayName += ` - ${organization.name}`;
        }

        return {
          id: user.id,
          fullName: displayName,
          avatar: user.pictureUrl,
        };
      } else {
        return Promise.reject('Not signed in');
      }
    },
    getPermissions: () => Promise.resolve('admin'),
  };

  return {
    authProvider,
    dataProvider,
    isLoading,
    isError,
  };
};
