import { AppBar, TitlePortal } from 'react-admin';
import { AppBarToolbar } from './AppBarToolbar';
import { MoonstarUserMenu } from './UserMenu';

export const MoonstarAppBar = () => {
  return (
    <AppBar color="secondary" toolbar={<AppBarToolbar />} userMenu={<MoonstarUserMenu />}>
      <TitlePortal />
    </AppBar>
  );
};
