import { useDataProvider } from 'react-admin';
import { useQuery } from '@tanstack/react-query';
import { AdminDataProvider } from '../adminDataProvider';

export const useFetchSendBirdAppId = () => {
  const provider = useDataProvider<AdminDataProvider>();

  return useQuery({
    queryKey: ['fetch-send-bird-app-id'],
    queryFn: provider.fetchSendBirdAppId,
    staleTime: 1000 * 60,
  });
};
