import { SystemStyleObject } from '@mui/system';

/**
 * Shrinks the last column in a react-admin datagrid
 * @example
 * ```tsx
 * <Datagrid sx={shrinkLastColumn}>
 *   <FullWidthColumn />
 *   <SmallColumn />
 * </Datagrid>
 * ```
 */
export const shrinkLastColumn: SystemStyleObject = {
  '& :last-child.RaDatagrid-rowCell': {
    width: '1px',
    whiteSpace: 'nowrap',
  },
};

/**
 * Shrinks the first column in a react-admin datagrid
 * @example
 * ```tsx
 * <Datagrid sx={shrinkFirstColumn}>
 *   <FullWidthColumn />
 *   <SmallColumn />
 * </Datagrid>
 * ```
 */
export const shrinkFirstColumn: SystemStyleObject = {
  '& :first-child.RaDatagrid-rowCell': {
    width: '1px',
    whiteSpace: 'nowrap',
  },
};
