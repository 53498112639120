import { z } from 'zod';
import { PostSchema } from '../Post/PostSchema';

export const RecognitionSchema = z
  .object({
    recognizedUserId: z.number({ message: 'Required' }).positive(),
    badgeId: z.number({ message: 'Required' }).positive(),
    title: z.string(),
  })
  .and(PostSchema);

export type Recognition = z.infer<typeof RecognitionSchema>;
