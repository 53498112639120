import { useDataProvider } from 'react-admin';
import { AdminDataProvider } from '../adminDataProvider';
import { useQuery, UseQueryOptions, QueryKey } from '@tanstack/react-query';

export interface ChartParams {
  createdAfter: string;
  createdBefore: string;
}

export interface Entry {
  id: number;
  createdOn: string;
  createdById: number;
}

export interface PostChartDataResponse {
  Posts: Entry[];
  Reactions: Entry[];
  Comments: Entry[];
}

export const usePostsChartData = (
  params: ChartParams,
  options?: UseQueryOptions<
    PostChartDataResponse,
    Error,
    PostChartDataResponse,
    QueryKey
  >,
) => {
  const dataProvider = useDataProvider<AdminDataProvider>();

  return useQuery<PostChartDataResponse>({
    queryKey: ['getPostsChartData', params],
    queryFn: () => dataProvider.getPostsChartData(params),
    staleTime: 1000 * 60,
    ...options,
  });
};
