import { HttpClient } from '../httpClient';

export const resendWelcomeSms =
  (httpClient: HttpClient) => async (request: { ids: string[] }) => {
    const successfulIds: string[] = [];
    const failedIds: string[] = [];

    const promises = request.ids.map(async (id) => {
      await httpClient
        .api(`/admin/sms/${id}/resend`, { method: 'POST' })
        .then(() => {
          successfulIds.push(id);
        })
        .catch(() => {
          failedIds.push(id);
        });
    });

    await Promise.all(promises);

    return {
      successfulIds,
      failedIds,
    };
  };
