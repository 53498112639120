import { App as SendbirdApp } from '@sendbird/uikit-react';
import { Title } from 'react-admin';
import { useFetchSendBirdAppId } from '../../../api/hooks/useFetchSendBirdAppId';
import { useGetCurrentUser } from '../../../hooks/useGetCurrentUser';
import { AppLoading } from '../../AppLoading';
import { sendBirdColorSet } from './colorSet';
import { useFeatureFlags } from '../../FeatureFlagGuard';

export const Groups = () => {
  const { data: featureFlags, isPending: isFeatureFlagsPending } = useFeatureFlags();

  const { data: user, isPending: isUserPending } = useGetCurrentUser();
  const { data: sendBirdApp, isPending: isSendBirdAppPending } = useFetchSendBirdAppId();

  if (isFeatureFlagsPending) {
    return null;
  }

  if (featureFlags && !featureFlags.withWebChat) {
    return null;
  }

  if (isUserPending || isSendBirdAppPending) {
    return (
      <>
        <Title title="Groups" />
        <AppLoading />
      </>
    );
  }

  return (
    <>
      <Title title="Groups" />
      <SendbirdApp
        appId={sendBirdApp?.appId ?? ''}
        userId={user?.id.toString() ?? ''}
        colorSet={sendBirdColorSet}
      />
    </>
  );
};
