import { isValid, parse, format } from 'date-fns';
import { z } from 'zod';

export interface User {
  id: number;
  pictureUrl: string;
  firstName: string;
  lastName: string;
  displayName: string;
  phoneNumber: string | null;
  email: string | null;
  jobTitleId: number;
  departmentId: number;
  regionId: number | null;
  unitsIds: number[];
  disabledFrom: string | null;
  role: string | null;
}

export type UserCsvRow = {
  firstName: string;
  lastName: string;
  email?: string;
  phoneNumber: string;
  startDate: string;
  department: string;
  jobTitle: string;
  region: string;
  unit: string;
  languageCountryCode: string;
};

export type UserCsvExportRow = UserCsvRow & {
  errorMessage: string;
};

export type UserCsvResultRow = UserCsvRow & {
  status: 'pending' | 'processing' | 'success' | 'error';
};

export type UserCsvValidationError = {
  property: string;
  message: string;
};

export interface UserLite {
  id: number;
  firstName: string;
  lastName: string;
}

const parseDate = (value: string, formats: string[]): string | null => {
  for (const f of formats) {
    const date = parse(value, f, new Date());
    if (isValid(date)) {
      return format(date, "yyyy-MM-dd'T'00:00:00.000'Z'");
    }
  }
  return null;
};

const dateSchema = z.string().transform((dateStr, ctx) => {
  const formats = ['dd/MM/yyyy', 'dd-MM-yyyy', 'dd-MMM-yyyy'];
  const date = parseDate(dateStr, formats);
  if (!date) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message:
        'Invalid date format. Please use one of the following formats: dd/MM/yyyy, dd-MM-yyyy or dd-MMM-yyyy',
    });
  }
  return date;
});

export const userSchema = z.object({
  firstName: z.string().min(1, { message: 'First name is required' }),
  lastName: z.string().min(1, { message: 'Last name is required' }),
  email: z
    .string()
    .optional()
    .refine(
      (value) =>
        value === undefined || value === '' || /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value),
      {
        message: 'Invalid email address',
      },
    ),
  phoneNumber: z
    .string()
    .regex(/^\+\d{1,15}$/, 'Phone number must be in format: +[countryCode][number]'),
  startDate: dateSchema,
  department: z.string().min(1, { message: 'Department is required' }),
  jobTitle: z.string().min(1, { message: 'Job title is required' }),
  region: z.string().min(1, { message: 'Region is required' }),
  unit: z.string().min(1, { message: 'Unit is required' }),
  languageCountryCode: z.enum(['en', 'ro'], {
    errorMap: () => ({ message: "Allowed languages: 'en' or 'ro'" }),
  }),
});
