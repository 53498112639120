import { Alert } from '@mui/material';
import { GroupChannel } from '@sendbird/chat/groupChannel';
import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { useNotify, useTranslate } from 'react-admin';
import { SendableMessage } from '../../../components/admin/GroupsCustom/ForwardMessageProvider';

type ForwardMessageParams = {
  message: SendableMessage;
  channels: GroupChannel[];
};

const forwardMessage = async ({ message, channels }: ForwardMessageParams) => {
  if (message.isFileMessage()) {
    const fetchRes = await fetch(message.url);
    if (!fetchRes.ok) throw new Error();
    const buffer = await fetchRes.arrayBuffer();

    for (const channel of channels) {
      channel.sendFileMessage({
        file: new Blob([buffer]),
        fileName: message.name,
        mimeType: message.type,
      });
    }
  } else if (message.messageType === 'user') {
    for (const channel of channels) {
      channel.sendUserMessage({ message: message?.message ?? '' });
    }
  } else {
    throw new Error();
  }
};

export const useForwardMessage = (
  options?: UseMutationOptions<void, Error, ForwardMessageParams>,
) => {
  const notify = useNotify();
  const translate = useTranslate();
  return useMutation<void, Error, ForwardMessageParams>({
    mutationKey: ['forward-message'],
    mutationFn: forwardMessage,
    ...options,
    onError: () => {
      notify(<Alert severity="error">{translate('groups.somethingWentWrong')}</Alert>, {
        autoHideDuration: 5000,
      });
    },
    onSuccess: (data, variable, context) => {
      options?.onSuccess?.(data, variable, context);
    },
  });
};
