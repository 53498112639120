import { FC } from 'react';
import { SimpleForm, SimpleFormProps } from 'react-admin';

export const MoonstarCreateForm: FC<SimpleFormProps> = (props) => {
  return (
    <SimpleForm mode="onChange" {...props}>
      {props.children}
    </SimpleForm>
  );
};
