import { Typography, TypographyProps } from '@mui/material';
import { ExpandableText } from './ExpandableText';

type PostPreviewContentProps = {
  content: string;
} & TypographyProps;

export const PostPreviewContent = ({ content, ...props }: PostPreviewContentProps) => {
  if (typeof content !== 'string') {
    return null;
  }

  return (
    <ExpandableText>
      <Typography whiteSpace="pre-wrap" {...props}>
        {content}
      </Typography>
    </ExpandableText>
  );
};
