import removeAccents from 'remove-accents';
import { useCallback, useMemo } from 'react';
import { UserDto } from '../../../api/customEndpoints/fetchAllUsers';

export const useUserSearch = <TUser extends UserDto = UserDto>(
  users: TUser[]
) => {
  const normalizedUsers = useMemo(
    () =>
      users.map((u) => ({
        searchFields: getSearchFields(u),
        user: u,
      })),
    [users]
  );

  const handleSearch = useCallback(
    (searchTerm: string) => {
      if (!searchTerm) return normalizedUsers.map((n) => n.user);

      const normalizedSearchTerms = removeAccents(
        searchTerm.toLowerCase()
      ).split(' ');

      return normalizedUsers
        .filter((u) =>
          normalizedSearchTerms.every((searchTerm) =>
            u.searchFields.some((field) => field.includes(searchTerm))
          )
        )
        .map((n) => n.user);
    },
    [normalizedUsers]
  );

  return handleSearch;
};

const getSearchFields = (u: UserDto) => {
  const searchFields: string[] = [];

  if (u.displayName) {
    searchFields.push(removeAccents(u.displayName.toLowerCase()));
  }
  if (u.jobTitle) {
    searchFields.push(removeAccents(u.jobTitle.toLowerCase()));
  }
  if (u.department) {
    searchFields.push(removeAccents(u.department.toLowerCase()));
  }
  for (const unit of u.units ?? []) {
    if (!unit) continue;
    searchFields.push(removeAccents(unit.toLowerCase()));
  }

  return searchFields;
};
