import { getCommonDefaultValues } from '../CommonDefaultValues';
import { getPostDefaultValues } from '../Post/PostDefaultValues';
import { Recognition } from './RecognitionSchema';

export const getRecognitionDefaultValues = (
  selectedTopicId: number | undefined,
): Recognition => ({
  ...getCommonDefaultValues(),
  ...getPostDefaultValues(selectedTopicId),

  title: '',

  // By default, inputs are invalid. User must select a value
  recognizedUserId: undefined as unknown as number,
  badgeId: undefined as unknown as number,
});
