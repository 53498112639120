import { regex } from 'react-admin';

export const phoneNumber = () => regex(/^\+\d{1,15}$/, 'moonstar.validation.phoneNumber');

export const requiredIf =
  <T>(check: (value: T) => boolean, message?: string) =>
  (_field: unknown, allValues: T) =>
    check(allValues) ? message ?? 'Required' : undefined;

type BlankFieldsSomeOf = {
  /**
   * If any of these fields are blank, show a "Required" error message
   */
  someFields: string[];
};
type BlankFieldsAllOf = {
  /**
   * If all of these fields are blank, show a "Required" error message
   */
  everyField: string[];
};
/**
 * If this field is blank, show a "Required" error message
 */
type BlankFieldsField = string;
type BlankFields = BlankFieldsSomeOf | BlankFieldsAllOf | BlankFieldsField;

export const requiredIfFieldsAreBlank = <T extends Record<string, unknown>>(
  fields: BlankFields,
  message?: string,
) =>
  requiredIf<T>((value) => {
    if (typeof fields === 'string') {
      return !value[fields];
    } else if ('everyField' in fields) {
      return fields.everyField.every((key) => !value[key]);
    } else {
      return fields.someFields.some((key) => !value[key]);
    }
  }, message);
