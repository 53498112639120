import { HttpClient } from '../httpClient';

export type FeatureFlags =
  | 'withChatbot'
  | 'withGrowApp'
  | 'withNudges'
  | 'withPulseChecks'
  | 'withVideos'
  | 'withAdminDashboard'
  | 'withAdminBadgeAnalytics'
  | 'withWebChat'
  | 'withFullVersionWebChat'
  | 'withNewOnboarding';

export type FeatureFlagResponse = Record<FeatureFlags, boolean>;

export const fetchFeatureFlags = (httpClient: HttpClient) => async () => {
  const response = await httpClient.api('/featureFlags');

  return response.json as FeatureFlagResponse;
};
