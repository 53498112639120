import { getCommonDefaultValues } from '../CommonDefaultValues';
import { Poll } from './PollSchema';

export const getPollDefaultValues = (defaultTopicId: number | undefined): Poll => ({
  ...getCommonDefaultValues(),
  topicId: defaultTopicId as number, // Zod schema marks the field as required, but it's undefined whilst loading
  title: '',
  options: [{ text: '' }, { text: '' }],
  pdf: undefined,
});
