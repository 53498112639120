import { ChangeEvent, FC, useCallback, useRef } from 'react';
import { Button, useDataProvider, useNotify, useTranslate } from 'react-admin';
import { Alert, Tooltip } from '@mui/material';
import GetAppIcon from '@mui/icons-material/GetApp';
import { AdminDataProvider } from '../../../api/adminDataProvider';
import { useMutation } from '@tanstack/react-query';
import { saveAs } from '../../../utils/saveAs';
import { UserCsvRow, UserCsvExportRow } from './types';
import Papa from 'papaparse';

export const ImportUsersWithoutUIButton: FC = () => {
  const translate = useTranslate();
  const label = translate('csv.buttonMain.tooltip');
  const importRef = useRef<HTMLInputElement>(null);
  const dataProvider = useDataProvider<AdminDataProvider>();
  const notify = useNotify();

  const onClickImport = useCallback(() => {
    if (!importRef.current) return;

    importRef.current.value = '';
    importRef.current.click();
  }, []);

  const parseCsv = (file: File): Promise<UserCsvRow[]> => {
    return new Promise((resolve, reject) => {
      Papa.parse<UserCsvRow>(file, {
        header: true,
        skipEmptyLines: true,
        transformHeader: (header) => header.trim(),
        transform: (value) => value.trim(),
        complete: (results) => {
          const filteredData = results.data.filter((row) =>
            Object.values(row).some((value) => value !== '' && value !== null),
          );
          resolve(filteredData);
        },
        error: (error) => reject(error),
      });
    });
  };

  const importUsers = useMutation({
    mutationFn: async (e: ChangeEvent<HTMLInputElement>) => {
      const file = e.target.files?.[0];
      if (!file) return;

      try {
        const jsonData: UserCsvRow[] = await parseCsv(file);
        return await dataProvider.uploadUsers(jsonData);
      } catch (error) {
        console.error('Error processing CSV file:', error);
        throw error;
      }
    },

    onSuccess: (e) => {
      if (!e || (e.status === 200 && !e.body)) {
        notify(<Alert severity="success">{translate('moonstar.import.success')}</Alert>);
      } else {
        const content = JSON.parse(e.body!) as UserCsvExportRow[];
        const totalFailedUsers = content.length;
        const result = Papa.unparse(content, {
          delimiter: ',',
          quotes: false,
          skipEmptyLines: true,
        });

        saveAs(result, 'import-errors.csv', 'text/csv');
        notify(
          <Alert severity="warning">
            {translate('moonstar.import.partial_success', {
              total_failed: totalFailedUsers,
            })}
          </Alert>,
        );
      }
    },
    onError: () => {
      notify(<Alert severity="error">{translate('moonstar.import.error')}</Alert>, {
        autoHideDuration: 5000,
      });
    },
  });

  return (
    <Tooltip title={label}>
      <div>
        <Button
          label="moonstar.import.label"
          onClick={onClickImport}
          disabled={importUsers.isPending}
        >
          <GetAppIcon style={{ transform: 'rotate(180deg)', fontSize: '20' }} />
        </Button>
        <input
          ref={importRef}
          type="file"
          style={{ display: 'none' }}
          onChange={importUsers.mutate}
          accept=".csv,.tsv"
        />
      </div>
    </Tooltip>
  );
};
