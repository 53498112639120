import { HttpClient } from '../httpClient';
import { PostChartDataResponse } from '../hooks/usePostsChartData';

export const getPostsChartData = (httpClient: HttpClient) => async (data: any) => {
  const response = await httpClient.api('/admin/posts/chart', {
    method: 'POST',
    body: JSON.stringify(data),
  });

  return response.json as PostChartDataResponse;
};
