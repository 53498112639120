import { Button, Link, Datagrid, TextField, useRecordContext } from 'react-admin';
import { MoonstarList } from '../Moonstar/MoonstarList';
import PeopleIcon from '@mui/icons-material/People';

export const CohortList = () => (
  <MoonstarList actions={false} sx={{ marginTop: '3em' }}>
    <Datagrid bulkActionButtons={false}>
      <TextField source="name" />
      <ViewCohortMembershipButton />
    </Datagrid>
  </MoonstarList>
);

const ViewCohortMembershipButton = () => {
  const record = useRecordContext();

  return (
    <Button
      component={Link}
      to={`/cohorts/${record?.id}/members`}
      startIcon={<PeopleIcon />}
      label="Members"
    />
  );
};
