import { SignedIn, SignedOut, SignIn } from '@clerk/clerk-react';
import { OrganizationGuard } from '../components/OrganizationGuard';
import { FC, PropsWithChildren } from 'react';

export const LoggedInGuard: FC<PropsWithChildren> = (props) => {
  return (
    <>
      <SignedIn>
        <OrganizationGuard>{props.children}</OrganizationGuard>
      </SignedIn>
      <SignedOut>
        <SignInPage />
      </SignedOut>
    </>
  );
};

const SignInPage: FC = () => (
  <div
    style={{
      margin: '1rem',
      display: 'flex',
      minHeight: '100vh',
      justifyContent: 'center',
      alignItems: 'center',
    }}
  >
    <SignIn
      // Makes the phone number input appear by default instead of the email input
      initialValues={{
        phoneNumber: '+',
      }}
      appearance={{
        elements: {
          // Hide the "sign up" UI
          footerAction: {
            display: 'none',
          },
        },
      }}
    />
  </div>
);
